import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Input, message } from "antd";
import QueueAnim from "rc-queue-anim";
import { useHistory } from "react-router-dom";
const { TextArea } = Input;

const QuestionFive = (props) => {
  const previous = (e) => {
    props.prevStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a">
          <h3 style={{ margin: "20px 0" }}>
            {props.data && props.data.questionId.question}
          </h3>

          <div style={{ height: 150, overflowY: "scroll" }}>
            <p style={{ color: "gray", fontSize: 16 }}>
              {" "}
              {props.data && props.data.answer}
            </p>
          </div>
          {/* <Form.Item 
            initialValue={props.data.answer}
            name="firstName"
            rules={[{ required: true, message: 'Please input your First Name!' }]}
            style={{marginTop: 50}}
            >
                <TextArea
                    disabled
                    value={props.data.answer}
                    style={{minHeight: 100}}
                    name='name'
                    type='text'
                    placeholder="Type your answer here...."
                />
            </Form.Item> */}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            {/* <Button size='large' htmlType="submit" type="primary">Finish </Button> */}
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default QuestionFive;
