import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Breadcrumb, Tooltip, Table, Space, Switch } from "antd";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useLazyQuery } from "react-apollo";
import { GET_USER_COURSE_DETAILS } from "../../../query/Queries";
import { getCookie } from "../../../helper";

const StudentCourseDetails = () => {
  const [modal, setModal] = useState(false);
  const history = useHistory();

  // Actions
  const actionHandle = (r) => {
    console.log("Response >>>>", r);
    if (r === "submitted") {
      return (
        <>
          <Tooltip title="Accept">
            <AiOutlineCheckCircle
              // onClick={() => handleApproveUser(r, r.original._id)}
              style={{ color: "green", fontSize: "25px", cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Decline">
            <AiOutlineCloseCircle
              // onClick={() => handleRejectUser(r, r.original._id)}
              style={{
                color: "red",
                fontSize: "25px",
                cursor: "pointer",
                marginLeft: 15,
              }}
            />
          </Tooltip>
        </>
      );
    } else if (r === "approved") {
      return (
        <p
          style={{
            color: "green",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Accepted
        </p>
      );
    } else if (r === "rejected") {
      return (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Rejected
        </p>
      );
    } else if (r === "pending") {
      return (
        <p
          style={{
            display: "inline-block",
            color: "black",
            fontSize: "12px",
            marginBottom: 0,
            width: "intrinsic" /* Safari/WebKit uses a non-standard name */,
            width: "-moz-max-content" /* Firefox/Gecko */,
            width: "-webkit-max-content" /* Chrome */,
            width: "max-content",
          }}
        >
          Application not submitted
        </p>
      );
    }
  };

  const { Column } = Table;

  const userId = getCookie("userId");
  console.log(userId, "userIdFrommCookien");

  const [userCourseDetailDataState, setUserCourseDetailDataState] = useState();

  const [userCourseDetailData, { data, loading, error }] = useLazyQuery(
    GET_USER_COURSE_DETAILS,
    {
      variables: { userId },

      onCompleted: () => {
        console.log(data, "userCourseDetailData");
        setUserCourseDetailDataState(data);
      },
      onError: () => {
        console.log(error, "error in course content detail");
      },
      fetchPolicy: "network-only",
    }
  );

  // const courseItem = (data) => {
  //   const filteredCourse = [];
  //   userCourseDetailDataState?.getUserCourseDetails?.map((item) => {
  //     if (data.courseId?.courseName === item.courseId.courseName) {
  //       filteredCourse.push(data.courseId.courseName);
  //     }
  //   });
  //   return filteredCourse?.map((item) => <p>{item}</p>);
  // };

  useEffect(() => {
    userCourseDetailData();
  }, []);

  // useEffect(() => {
  //   const data = [
  //     {
  //       label: "Book1",
  //       data: "US edition",
  //     },
  //     {
  //       label: "Book1",
  //       data: "UK edition",
  //     },
  //     {
  //       label: "Book2",
  //       data: "CAN edition",
  //     },
  //     {
  //       label: "Book3",
  //       data: "CAN edition",
  //     },
  //     {
  //       label: "Book3",
  //       data: "CANII edition",
  //     },
  //   ];

  //   const filteredArr = data.reduce((acc, current) => {
  //     const x = acc.find((item) => item.label === current.label);
  //     if (!x) {
  //       const newCurr = {
  //         label: current.label,
  //         data: [current.data],
  //       };
  //       return acc.concat([newCurr]);
  //     } else {
  //       const currData = x.data.filter((d) => d === current.data);
  //       if (!currData.length) {
  //         const newData = x.data.push(current.data);
  //         const newCurr = {
  //           label: current.label,
  //           data: newData,
  //         };
  //         return acc;
  //       } else {
  //         return acc;
  //       }
  //     }
  //   }, []);

  //   console.log(filteredArr, "filteredArr");
  // }, []);

  const courseDetailObj = userCourseDetailDataState?.getUserCourseDetails;

  const [mergedCourseDetail, setMergedCourseDetail] = useState();

  useEffect(() => {
    console.log(courseDetailObj, "courseDetailObj");

    const filteredArr1 = courseDetailObj?.reduce((acc, current) => {
      const x = acc.find((item) => item.courseId._id === current.courseId._id);
      if (!x) {
        const newCurr = {
          courseId: current.courseId,
          data: [current.topicName],
        };
        return acc.concat([newCurr]);
      } else {
        const currData = x.data.filter((d) => d === current.topicName);
        if (!currData.length) {
          const newData = x.data.push(current.topicName);
          const newCurr = {
            courseId: current.courseId,
            topicName: newData,
          };
          return acc;
        } else {
          return acc;
        }
      }
    }, []);

    console.log(filteredArr1, "filteredArr1");

    setMergedCourseDetail(courseDetailObj);
  }, [courseDetailObj]);

  return (
    <div style={{ width: "100%" }}>
      {/* <ContentHeader
        title="Course Content Detail"
        handleAddNewCourseContent={handleAddNewCourseContent}
        handleTextSearch={textboxSearch}
        // handleSelectSearch={selectboxSearch}
        // count={props.tableData ? props.tableData.length : 0}
        count={courseData.length}
      /> */}
      <div>
        <h2>
          <span style={{ color: "gray" }}>Student's Courses</span>
          {/* {courseName} */}
        </h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_user">Manage User</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Student Courses Details</Breadcrumb.Item>
      </Breadcrumb>
      {/* {!courseData ? (
        "loading..."
      ) : ( */}

      <Table dataSource={mergedCourseDetail}>
        <Column
          title="Course Name"
          key="courseName"
          render={(data) => {
            return <p>{data.courseId?.courseName}</p>;

            // const filteredCourse = [];
            // userCourseDetailDataState?.getUserCourseDetails?.map((item) => {
            //   if (data.courseId?.courseName === item.courseId.courseName) {
            //     filteredCourse.push(data.courseId.courseName);
            //   }
            // });
            // return filteredCourse?.map((item) => <p>{item}</p>);
          }}
        />
        <Column
          title="Week 1"
          key="week1"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 1
            );
            console.log("filteredTestData", filteredTestData);
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            // console.log("filteredPassData", filteredPassData.length);

            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
        <Column
          title="Week 2"
          key="week2"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 2
            );
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
        <Column
          title="Week 3"
          key="week3"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 3
            );
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
        <Column
          title="Week 4"
          key="week4"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 4
            );
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
        <Column
          title="Week 5"
          key="week5"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 5
            );
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
        <Column
          title="Week 6"
          key="week6"
          render={(data) => {
            const filteredData = data.weeks;
            const filteredTestData = filteredData.filter(
              (item) => item.week === 6
            );
            const filteredPassData = filteredTestData.filter(
              (item) => item.status === "pass"
            );
            return (
              <>
                {filteredTestData.length !== 0 ? (
                  <p>
                    {filteredTestData.length === filteredPassData.length
                      ? "Yes"
                      : "No"}
                  </p>
                ) : (
                  <p></p>
                )}
              </>
            );
          }}
        />
      </Table>
      {/* )} */}
    </div>
  );
};

export default StudentCourseDetails;
