import { combineReducers } from "redux";
import getAllUsers from "./getAllUsers";
import getAllCourses from "./getAllCourses";
import getAllLibraries from "./getAllLibraries";
import getAllContentDetails from "./getAllContentDetails";

import appReducer from "./App";
import isLoggedIn from "./isLoggedin";
import getAllSalon from "./getAllCourses";
import userStatus from "./userStatus";

export default combineReducers({
  App: appReducer,
  getAllUsers,
  getAllCourses,
  isLoggedIn,
  getAllSalon,
  getAllLibraries,
  getAllContentDetails,
  userStatus,
});
