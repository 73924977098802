import React, {useState, useEffect} from 'react';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { message, Button, Space } from 'antd';
import AboutInfo from '../studentInformation/AboutInfo'
import ContactInfo from '../studentInformation/ContactInfo'
import ParentsInfo from '../studentInformation/ParentsInfo'
import SchoolInfo from '../studentInformation/SchoolInfo'
import HearAbout from '../studentInformation/HearAbout'
import {useHistory} from 'react-router-dom'

const StudentInfoFormContainer = (props) => {
let history = useHistory()

// const [step, setStep] = useState(1)

// const nextStep = () => {
//   setStep(step +1)
// }

// const prevStep = () => {
//   setStep(step -1)
// }

useEffect(() => {
    setInfoState(props.data)
}, [props.data])


const [infoState, setInfoState] = useState({
    firstName: '',
    lastName: '',
    selectCity: '',
    gender: '',
    birthday: '',
    email: '',
    phone: '',
    isJob: '',
    isMaster: '',
    isInter: '',
    primeGuardianFirstName: '',
    primeGuardianLastName: '',
    primeGuardianEmail: '',
    secGuardianFirstName: '',
    secGuardianLastName: '',
    secGuardianEmail: '',
    grade: '',
    institute: '',
    knowThrough: []
})
console.log(infoState, 'infoState')


  switch(props.step) {
    case 1:
        return <AboutInfo
                nextStep={props.nextStep}
                infoState={infoState}
                setInfoState={setInfoState}
                data={props.data && infoState}
                />
    case 2:
        return <ContactInfo
                nextStep={props.nextStep}
                prevStep={props.prevStep}
                infoState={infoState}
                setInfoState={setInfoState}
                data={props.data && infoState}
                />
    case 3:
        return <ParentsInfo
                nextStep={props.nextStep}
                prevStep={props.prevStep}
                infoState={infoState}
                setInfoState={setInfoState}
                data={props.data && infoState}
                />
    case 4:
        return <SchoolInfo
                nextStep={props.nextStep}
                prevStep={props.prevStep}
                infoState={infoState}
                setInfoState={setInfoState}
                data={props.data && infoState}
                />
   default:
        return <HearAbout
                infoState={infoState}
                setInfoState={setInfoState}
                prevStep={props.prevStep}
                data={props.data && infoState}
                />                
    }
}

export default StudentInfoFormContainer;

