import React, { useState } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Input, Radio } from "antd";
import {
  BookFilled,
  UserOutlined,
  SafetyCertificateFilled,
} from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const SchoolInfo = (props) => {
  const previous = (e) => {
    props.prevStep();
  };

  const next = (e) => {
    props.nextStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h3 style={{ textAlign: "center", margin: "20px " }}>
            Tell us about your current school year
          </h3>
          <p style={{ marginBottom: 0 }}>Grade:</p>
          <Form.Item initialValue={props.infoState.grade} name="grade">
            <InputWrapper
              disabled
              prefix={<BookFilled style={{ marginRight: 5 }} />}
              placeholder="What grade/class you will be starting in next 3 months?*"
            />
          </Form.Item>

          <p style={{ marginBottom: 0 }}>Institute:</p>
          <Form.Item initialValue={props.infoState.institute} name="school">
            <InputWrapper
              disabled
              prefix={<SafetyCertificateFilled style={{ marginRight: 5 }} />}
              placeholder="What Educational Institute you will be attending in next 3 months?"
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="isInter"
            valuePropName={props.infoState.isInter}
          >
            <Radio checked={props.infoState.isInter} style={{ color: "gray" }}>
              I have completed my Intermediate/A levels and would apply for a
              Bachelor’s degree.
            </Radio>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="forJob"
            valuePropName={props.infoState.isJob}
          >
            <Radio checked={props.infoState.isJob} style={{ color: "gray" }}>
              I have completed my Bachelor’s degree and would like to apply for
              a job in future.
            </Radio>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            name="forMaster"
            valuePropName={props.infoState.isMaster}
          >
            <Radio checked={props.infoState.isMaster} style={{ color: "gray" }}>
              I have completed my Bachelor’s degree and would like to apply for
              a post graduate/Master’s degree.
            </Radio>
          </Form.Item>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button size="large" htmlType="submit" type="primary">
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default SchoolInfo;
const InputWrapper = styled(Input)`
  &.ant-input-affix-wrapper-disabled .ant-input[disabled] {
    color: #666666;
  }
`;
