let g = 0;
const initialState = {
  getAllContentData: [],
};

const getAllContentDetails = (state = initialState, action) => {
  // console.log(action.payload, "action payload");
  switch (action.type) {
    case "GET_ALL_CONTENT_DETAILS":
      initialState.getAllContentData = action.payload;
      return Object.assign({}, state, initialState);

    case "ADD_NEW_CONTENT_DETAILS":
      return {
        ...initialState,
        getAllContentData: [action.payload, ...initialState.getAllContentData],
        garbage: g++,
      };

    case "CHANGE_CONTENT_DETAILS_STATUS":
      const topicStatus = initialState.getAllContentData.find(
        (item) => item._id === action.payload.id
      );
      topicStatus.status = action.payload.getAllContentData;

      return {
        ...state,
        getAllContentData: initialState.getAllContentData,
        garbage: g++,
      };

    default:
      return state;
  }
};

export default getAllContentDetails;
