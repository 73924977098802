export const userAcceptedStatus = (data) => {
  return { type: "USER_ACCEPTED_STATUS", payload: data };
};

export const userDeclineStatus = (data) => {
  return { type: "USER_DECLINE_STATUS", payload: data };
};

export const getUserStatus = (data) => {
  return { type: "GET_USER_STATUS", payload: data };
};
