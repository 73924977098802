import React from 'react';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Form, Button, Input} from 'antd'
import {UserOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import styled from 'styled-components'

const ContactInfo = (props) => {
    console.log(props.data, 'props data')

const previous = (e) => {
        props.prevStep();
    }

const next  = (e) => {
        props.nextStep();
    }    

return (
    <div style={{width: '80%', marginBottom: 20}}>
    <QueueAnim type='right' delay={300} className="queue-simple">
    <Form key='a' onFinish={next}>
        <h3 style={{textAlign: 'center', margin: '20px '}}>In case we need to contact you</h3>
        <p style={{marginBottom: 0}}>Email:</p>
         <Form.Item 
         initialValue={props.data && props.data.email}
         name="email"
         >
             <InputWrapper
                disabled
                 value={props.data && props.data.email}
                 prefix={<UserOutlined style={{marginRight: 5}} />}
                 placeholder="your@example.com*"
                 onChange={(e) => props.setInfoState({...props.infoState, email: e.target.value})}
             />
         </Form.Item>

         <p style={{marginBottom: 0}}>Phone number:</p>
         <Form.Item initialValue={props.data && props.data.phone}>
             <InputWrapper 
                disabled
                 value={props.data && props.data.phone}
                 prefix={<UserOutlined style={{marginRight: 5}}/>}
                 placeholder="Your Mobile Number*"
                 onChange={(e) => props.setInfoState({...props.infoState, phone: props.data.phone})}
             />
         </Form.Item>

         <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 100}}>
         <Button  size='large' onClick={previous} style={{backgroundColor: 'lightgray'}}> Previous</Button>
         <Button  size='large' htmlType="submit" type="primary">Next </Button>
         </div>
     </Form>
     </QueueAnim>
 </div>
  )
}

export default ContactInfo;
const InputWrapper = styled(Input)`
&.ant-input-affix-wrapper-disabled .ant-input[disabled]{
    color: #666666;
}
`