import React from 'react';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import {Form, Button, Select, Input, DatePicker} from 'antd'
import { UserOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim';
import moment from 'moment'
import styled from 'styled-components'

const AboutInfo = (props) => {
const currentDate = new Date()
const date = moment(currentDate).format("Do MMM YYYY");  

const next = (e) => {
    props.nextStep()
}

return (      
    <div style={{width: '80%', marginBottom: 20}}>
       <QueueAnim type='right' delay={300} className="queue-simple">
       <Form name="basic" key='a' onFinish={next}>
            <h3 style={{textAlign: 'center', margin: '20px '}}>Let's start with the basic information</h3>
            <p style={{marginBottom: 0}}>First Name:</p>
            <Form.Item 
            style={{color: '#666666'}}
            initialValue={props.data && props.data.firstName}
            name='firstName'
            >
                <InputWrapper
                    disabled
                    value={props.data && props.data.firstName}
                    prefix={<UserOutlined style={{marginRight: 5}} />}
                    placeholder="First Name*"
                />
            </Form.Item>

            <p style={{marginBottom: 0}}>Last Name:</p>
            <Form.Item 
            style={{color: '#666666'}}
            initialValue={props.data && props.data.lastName}
            name="lastName"
            >
                <InputWrapper
                    disabled
                    value={props.data && props.data.lastName}
                    type='text'
                    prefix={<UserOutlined style={{marginRight: 5}}/>}
                    placeholder="Last Name*"
                />
            </Form.Item>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{marginBottom: 0}}>City:</p>
            <Form.Item
            style={{color: '#666666'}}
            initialValue={props.data && props.data.city}
            name="city"
            >
            <SelectWrapper
                disabled
                value={props.data && props.data.city}
                placeholder="Which city are you applying for?*"
                style={{ width: 260, borderLeft: 'none', }}>
                <Select.Option value='Karachi'>Karachi</Select.Option>
                <Select.Option value='Lahore'>Lahore</Select.Option>
                <Select.Option value='Multan'>Multan</Select.Option>
            </SelectWrapper>
            </Form.Item>
            </div>
            
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{marginBottom: 0}}>Gender:</p>
            <Form.Item
            style={{color: '#666666'}}
            initialValue={props.infoState.gender}
            name="gender"
            >
            <SelectWrapper
                disabled
                value={props.infoState.gender}
                style={{ width: 120}}
                placeholder="Gender"
                >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="other">Other</Select.Option>
            </SelectWrapper>
            </Form.Item>
            </div>

            <div style={{display: 'flex', flexDirection: 'column'}}>
            <p style={{marginBottom: 0}}>Date of birth:</p>
            <Form.Item  
                style={{color: '#666666'}}
                initialValue={props.infoState.birthday}
                name="date"
                >
                <InputWrapper
                type="text"
                value={props.infoState.birthday}
                disabled
                placeholder='Birth Date'/>
            </Form.Item>
            </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 20}}>
                <Button  size='large' htmlType="submit" type="primary"> Next</Button>
            </div>
        </Form>  
       </QueueAnim>
    </div>
  )
}

export default AboutInfo;

const InputWrapper = styled(Input)`
&.ant-input-affix-wrapper-disabled .ant-input[disabled]{
    color: #666666;
}
`;

const SelectWrapper = styled(Select)`
&.ant-select-single.ant-select-show-arrow 
.ant-select-selection-item, 
.ant-select-single.ant-select-show-arrow 
.ant-select-selection-placeholder{
    color: #666666;
}
`