import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { ADD_COURSE_DETAIL } from "../../query/Mutations";
import { useMutation } from "@apollo/client";
import { Breadcrumb, Select, Row, Col, Radio, Form, Tooltip } from "antd";
import NewCourseWrapper from "../../components/newCourse/NewCourseWrapper";
import RadioWrapper from "../../components/radioButton/Index";
import InputWrapper from "../../components/input/index";
import { getLinkPreview } from "link-preview-js";
import { InfoCircleOutlined } from "@ant-design/icons";
import TextAreaWrapper from "../../components/input/TextArea";
import { useHistory, Link, Route } from "react-router-dom";
import { assertCompositeType } from "graphql";
import { getCookie } from "../../helper";

const AddNewDurationContent = (props) => {
  let history = useHistory();
  // let courseId = localStorage.getItem("courseId");
  // let courseName = localStorage.getItem("courseName");
  let courseId = getCookie("courseId");
  let courseName = getCookie("courseName");
  const [error, setError] = useState("");
  // console.log(error, "error >>>");
  const [addCourse, setAddCourse] = useState({
    title: "",
    description: "",
    link: "",
    week: "",
    courseId: courseId,
    materialType: "",
  });
  const [linkData, setLinkData] = useState();
  const [isArticle, setIsArticle] = useState(false);
  const [wait, setWait] = useState(false);
  const [addCourseContent, { loading }] = useMutation(ADD_COURSE_DETAIL);
  const handleSubmit = async (e, id) => {
    try {
      const res = await addCourseContent({
        variables: {
          courseId: addCourse.courseId,
          description: addCourse.description,
          materialUrl: addCourse.link,
          materialType: addCourse.materialType,
          week: parseInt(addCourse.week),
          topicName: addCourse.title,
        },
      });
      // console.log(res, "res add course content");
      history.push("/course_content_details");
      //   props.dispatch({
      //     type: "ADD_NEW_COURSE",
      //     payload: res.data.addCourse,
      //   });
    } catch (error) {
      setError(error.message.slice(14));
      // console.log(error);
    }
  };

  const [data, setData] = useState([]);

  useEffect(() => setData(props.tableData), [props.tableData]);
  // console.log("Options >> ", addCourse.materialType);

  // Implementing link validation
  const [imageError, setImageError] = useState(false);
  const handleOnLoad = () => {
    setImageError(false);
  };
  const handleOnError = () => {
    setImageError(true);
  };
  const isImage = () => {
    return !imageError && linkData && linkData.images.length > 0;
  };
  const isDisplayble = () => {
    return isImage() && linkData && linkData.description !== undefined;
  };
  const isVideo = () => {
    if (
      addCourse.link &&
      (addCourse.link.slice(0, 32) === "https://www.youtube.com/watch?v=" ||
        addCourse.link.slice(0, 32) === "https://www.youtube.com/watch?v=")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const isLink = () => {
    return (
      addCourse.link &&
      (addCourse.link.slice(0, 8) === "https://" ||
        addCourse.link.slice(0, 8) === "https:\\\\")
    );
  };
  const isSubmittable = () => {
    if (addCourse.materialType === "article") {
      return isDisplayble() && isLink() && !isVideo();
    } else if (addCourse.materialType === "video") {
      return isVideo();
    } else {
      return false;
    }
  };
  // console.log("Link Data >>> ", linkData);
  // console.log("Is Image >>> ", isImage());
  // console.log("Is Submittable >>> ", isSubmittable());
  // console.log("Article Link Input >>>", addCourse.link);
  // console.log("Is Link >>> ", isLink());
  return (
    <div>
      <div>
        <img
          src={linkData && linkData.images.length > 0 && linkData.images[0]}
          alt="checkImage"
          onLoad={handleOnLoad}
          onError={handleOnError}
          width="2"
          height="2"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <h2 style={{ fontWeight: "bold" }}>Add New Duration Content</h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_course">Manage Course</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/course_content_details">
            Course Content Detail ({courseName})
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Duration Content</Breadcrumb.Item>
      </Breadcrumb>
      <NewCourseWrapper>
        <Form onFinish={handleSubmit}>
          <Row>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Week:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.week}
                name="week"
                rules={[
                  {
                    required: true,
                    message: error ? `${error}` : "Please enter No of Week!",
                  },
                ]}
              >
                <InputWrapper
                  type="number"
                  min="1"
                  max="7"
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, week: e.target.value })
                  }
                  placeHolder="Topic blonging to week"
                />
              </Form.Item>
              <p style={{ color: "red", marginBottom: 0 }}>{error}</p>
            </Col>
          </Row>
          <Row align="middle" style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Title of Topic:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.title}
                name="coursTitle"
                rules={[
                  { required: true, message: "Please enter title of Topic!" },
                ]}
              >
                <InputWrapper
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, title: e.target.value })
                  }
                  placeHolder="Title of Topic"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Description:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.description}
                name="Description"
                rules={[
                  { required: true, message: "Please enter Description!" },
                ]}
              >
                <TextAreaWrapper
                  maxLength={700}
                  rows={8}
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, description: e.target.value })
                  }
                  placeHolder="Description"
                />
              </Form.Item>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  bottom: 25,
                }}
              >
                <span>{addCourse?.description.length}/700</span>
              </div> */}
            </Col>
          </Row>
          <Row align="middle">
            <Col span={6} offset={6}>
              <RadioWrapper
                value={addCourse.materialType}
                onChange={(e) =>
                  setAddCourse({ ...addCourse, materialType: e.target.value })
                }
              >
                <Radio
                  defaultChecked={true}
                  style={{ marginRight: "25px" }}
                  value={"article"}
                  onClick={() => {
                    setIsArticle(true);
                  }}
                >
                  Articles
                </Radio>
                <Radio
                  onClick={() => {
                    setIsArticle(false);
                  }}
                  value={"video"}
                >
                  Videos
                </Radio>
              </RadioWrapper>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ margin: 0 }}
                initialValue={addCourse.link}
                // name="link"
                // rules={[{ required: true, message: "Please input Link!" }]}
              >
                <InputWrapper
                  onChange={async (e) => {
                    setAddCourse({ ...addCourse, link: e.target.value });
                    setWait(true);
                    await getLinkPreview(
                      `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                    ).then((data) => {
                      setLinkData(data);
                      setWait(false);
                    });
                  }}
                  placeHolder={
                    isArticle == true
                      ? "https://www.google.com/"
                      : "https://www.youtube.com/"
                  }
                />
                {addCourse.link.length !== 0 && !isSubmittable() ? (
                  <p style={{ marginTop: "3px", color: "#f00" }}>
                    Please enter valid link!
                    <Tooltip title="The link you provided is invalid, the emerge data could not be generated. To enable this, please input a valid link that could provide with the sufficient data.">
                      <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                    </Tooltip>
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 20 }}>
            <Col offset={18} style={{ marginRight: 10 }}>
              <StyledButton
                style={{ background: "#2A2A2A" }}
                onClick={() => history.push("/course_content_details")}
              >
                Cancel
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                disabled={
                  !isSubmittable() ||
                  addCourse.link === null ||
                  addCourse.link === ""
                }
                htmlType="submit"
                loading={addCourse.link !== "" && isLink() && (loading || wait)}
              >
                Save
              </StyledButton>
            </Col>
          </Row>
        </Form>
      </NewCourseWrapper>
    </div>
  );
};

export default // connect((state) => {
//   return{
//       tableData: state.getAllCourses.AllCourses,
//       garbage: state.getAllSalon.garbage,
//   }
// })
AddNewDurationContent;
