import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import { connect } from "react-redux";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { getLinkPreview } from "link-preview-js";
import { ADD_COURSE } from "../../query/Mutations";
import { useMutation } from "@apollo/client";
import {
  Collapse,
  Select,
  Row,
  Col,
  Radio,
  Form,
  Breadcrumb,
  Tooltip,
  Input,
} from "antd";
import NewCourseWrapper from "../../components/newCourse/NewCourseWrapper";
import RadioWrapper from "../../components/radioButton/Index";
import InputWrapper from "../../components/input/index";
import TextAreaWrapper from "../../components/input/TextArea";
import { useHistory, Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;
const AddNewCourse = (props) => {
  let history = useHistory();

  const [addCourse, setAddCourse] = useState({
    courseName: "",
    description: "",
    link: "",
  });
  const [linkData, setLinkData] = useState();
  const [wait, setWait] = useState(false);
  const [addNewCourse, { loading }] = useMutation(ADD_COURSE);
  const handleSubmit = async (e, id) => {
    try {
      const res = await addNewCourse({
        variables: {
          courseName: addCourse.courseName,
          description: addCourse.description,
          courseImageURL: addCourse.link,
        },
      });
      // console.log(res, "res add course");
      props.dispatch({
        type: "ADD_NEW_COURSE",
        payload: addCourse,
      });
      history.push("/manage_course");
    } catch (error) {
      // console.log(error);
    }
  };

  const [data, setData] = useState([]);
  useEffect(() => setData(props.tableData), [props.tableData]);

  // Implementing link validation
  // const [imageError, setImageError] = useState(false);
  // const handleOnLoad = () => {
  //   setImageError(false);
  // };
  // const handleOnError = () => {
  //   setImageError(true);
  // };
  // const isImage = () => {
  //   return !imageError && linkData && linkData.images.length > 0;
  // };
  // const isDisplayble = () => {
  //   return isImage() && linkData && linkData.description !== undefined;
  // };
  const isVideo = () => {
    if (
      addCourse.link &&
      (addCourse.link.slice(0, 32) === "https://www.youtube.com/watch?v=" ||
        addCourse.link.slice(0, 32) === "https:\\\\www.youtube.com/watch?v=")
    ) {
      return true;
    } else {
      return false;
    }
  };
  // const isLink = () => {
  //   return (
  //     addCourse.link &&
  //     (addCourse.link.slice(0, 8) === "https://" ||
  //       addCourse.link.slice(0, 8) === "https:\\\\")
  //   );
  // };
  const isSubmittable = () => {
    // return isDisplayble() && isVideo() && isLink();
    return isVideo();
  };
  // console.log("Link Data >>>", linkData);
  return (
    <div>
      {/* <div>
        <img
          src={linkData && linkData.images.length > 0 && linkData.images[0]}
          alt="checkImage"
          onLoad={handleOnLoad}
          onError={handleOnError}
          width="2"
          height="2"
          style={{ display: "none" }}
        />
      </div> */}
      <div>
        <h2 style={{ fontWeight: "bold" }}>ADD NEW COURSE</h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_course">Manage Course</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Course</Breadcrumb.Item>
      </Breadcrumb>
      <NewCourseWrapper>
        <Form onFinish={handleSubmit}>
          <Row>
            <Col span={18} offset={6}>
              <RadioWrapper defaultValue="weekly">
                <Radio disabled value="daily">
                  Daily
                </Radio>
                <Radio
                  defaultChecked={true}
                  style={{ margin: "0 60px" }}
                  value="weekly"
                >
                  Weekly
                </Radio>
                <Radio disabled value="monthly">
                  Monthly
                </Radio>
              </RadioWrapper>
            </Col>
          </Row>
          <Row align="middle" style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Title of Course:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.courseName}
                name="coursTitle"
                rules={[
                  { required: true, message: "Please enter title of course!" },
                ]}
              >
                <InputWrapper
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, courseName: e.target.value })
                  }
                  placeholder="Title of Course"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Description:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.description}
                name="Description"
                rules={[
                  { required: true, message: "Please enter Description!" },
                ]}
              >
                <TextAreaWrapper
                  showCount
                  maxLength={700}
                  rows={8}
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, description: e.target.value })
                  }
                  placeholder="Description"
                />
              </Form.Item>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  bottom: 25,
                }}
              >
                <span>{addCourse?.description.length}/700</span>
              </div> */}
            </Col>
          </Row>
          <Row align="middle">
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Introduction Video Link:</p>
              {/* <RadioWrapper >
                <Radio style={{marginRight: '50px'}} value={2}>Articles</Radio>
                <Radio value={3}>Videos</Radio>
                </RadioWrapper> */}
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.link}
                // name="link"
                // rules={[{ required: true, message: "Please input Link!" }]}
              >
                <InputWrapper
                  onChange={async (e) => {
                    setWait(true);
                    setAddCourse({ ...addCourse, link: e.target.value });
                    await getLinkPreview(
                      `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                    ).then((data) => {
                      setLinkData(data);
                      setWait(false);
                    });
                  }}
                  placeholder="https://www.youtube.com/"
                />
                {addCourse.link.length !== 0 && !isSubmittable() ? (
                  <p style={{ marginTop: "3px", color: "#f00" }}>
                    Please enter valid link!
                    <Tooltip title="The link you provided is invalid, the emerge data could not be generated. To enable this, please input a valid link that could provide with the sufficient data.">
                      <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                    </Tooltip>
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 20 }}>
            <Col offset={18} style={{ marginRight: 10 }}>
              <StyledButton
                style={{ background: "#2A2A2A" }}
                onClick={() => history.push("/manage_course")}
              >
                Cancel
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                disabled={!isSubmittable() || addCourse.link === ""}
                htmlType="submit"
                loading={
                  addCourse.link !== "" && isVideo() && (loading || wait)
                }
              >
                Save
              </StyledButton>
            </Col>
          </Row>
        </Form>
      </NewCourseWrapper>
    </div>
  );
};

export default connect((state) => {
  return {
    tableData: state.getAllCourses.AllCourses,
    garbage: state.getAllSalon.garbage,
  };
})(AddNewCourse);
