let g = 0;
const initialState = {
  AllUsers: [],
};
const getAllUsers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USERS":
      // console.log("Action Payload >>> ", action.payload);
      initialState.AllUsers = action.payload;
      // return Object.assign({}, state, initialState);       Both methods can be used
      return { ...state, AllUsers: action.payload.reverse() };

    case "CHANGE_USER_STATUS":
      const user = initialState.AllUsers.find(
        (item) => item._id === action.payload.id
      );
      console.log(user, "user >>");
      user.isPaid = action.payload.isPaid;

      return {
        ...state,
        AllUsers: initialState.AllUsers,
        garbage: g++,
      };

    case "CHANGE_APPLICATION_STATUS":
      const applicationStatus = initialState.AllUsers.find(
        (item) => item._id === action.payload.id
      );
      applicationStatus.applicationStatus = action.payload.applicationStatus;

      return {
        ...state,
        AllUsers: initialState.AllUsers,
        garbage: g++,
      };
    default:
      return state;
  }
};

export default getAllUsers;
