import React from "react";
import { Modal, Typography, Row, Col } from "antd";
import Styled from "styled-components";

const ModalWrapper = Styled(Modal)`
    width: 700px !important

`;

const CourseDetailModal = (props) => {
  console.log(props, "props in modal");
  const {
    description,
    materialType,
    materialUrl,
    topicName,
    week,
    courseId,
  } = props.data;

  return (
    <>
      <ModalWrapper
        title={props.title}
        style={{ borderRadius: 10 }}
        visible={props.modal}
        onOk={() => props.setModal(false)}
        onCancel={() => props.setModal(false)}
        footer=""
      >
        <Row align="middle">
          <Col>
            <h3>Number of Week: </h3>
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <h4>{week}</h4>
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <h3>Topic Name: </h3>
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <h4>{topicName}</h4>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={24}>
            <h3>Description: </h3>
          </Col>
          <Col>
            <h4>{description}</h4>
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <h3>Type of Content: </h3>
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <h4>{materialType}</h4>
          </Col>
        </Row>
        <Row align="middle">
          <Col>
            <h3>Link: </h3>
          </Col>
          <Col style={{ marginLeft: 10 }}>
            <Typography.Link style={{ position: "relative", bottom: "4px" }}>
              <a href={materialUrl} target="_blank">
                {materialUrl}
              </a>
            </Typography.Link>
          </Col>
        </Row>
      </ModalWrapper>
    </>
  );
};

export default CourseDetailModal;
