import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { ADD_LIBRARY } from "../../query/Mutations";
import { GET_ALL_CATEGORIES } from "../../query/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { Breadcrumb, Select, Row, Col, Radio, Form, Tooltip } from "antd";
import NewCourseWrapper from "../../components/newCourse/NewCourseWrapper";
import RadioWrapper from "../../components/radioButton/Index";
import InputWrapper from "../../components/input/index";
import TextAreaWrapper from "../../components/input/TextArea";
import { useHistory, Link } from "react-router-dom";
import { getLinkPreview } from "link-preview-js";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddNewLibraryContent = (props) => {
  let history = useHistory();

  const [allCategories, setAllCategories] = useState("");
  const [error, setError] = useState("");
  const [addCourse, setAddCourse] = useState({
    topicName: "",
    description: "",
    topicUrl: "",
    tags: "",
    topicType: "",
  });
  const [linkData, setLinkData] = useState();
  const [isArticle, setIsArticle] = useState(false);
  const [wait, setWait] = useState(false);
  // ==========Get all categories ============
  const { data, loading: categoryLoading, error: categoryError } = useQuery(
    GET_ALL_CATEGORIES,
    {
      onCompleted: () => {
        // console.log(data.getAllCategories, "getall gategories=====");
        setAllCategories(data.getAllCategories);
      },
      onError: () => {
        // console.log(categoryError, "error in get all categories");
      },
    }
  );

  // ============Add topic Mutation=======
  const [addLibrary, { loading }] = useMutation(ADD_LIBRARY);
  const handleSubmit = async (e, id) => {
    try {
      const res = await addLibrary({
        variables: {
          description: addCourse.description,
          topicUrl: addCourse.topicUrl,
          topicType: addCourse.topicType,
          category: addCourse.tags,
          topicName: addCourse.topicName,
        },
      });
      props.dispatch({
        type: "ADD_NEW_LIBRARY",
        payload: addCourse,
      });
      history.push({
        pathname: "/manage_library",
      });
    } catch (error) {
      setError(error.message.slice(14));
      // console.log(error);
    }
  };

  function handleChange(value) {
    setAddCourse({ ...addCourse, tags: value });
  }

  // Implementing link validation
  const [imageError, setImageError] = useState(false);
  const handleOnLoad = () => {
    setImageError(false);
  };
  const handleOnError = () => {
    setImageError(true);
  };
  const isImage = () => {
    if (!imageError && linkData && linkData.images.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const isVideo = () => {
    if (
      addCourse.topicUrl &&
      addCourse.topicUrl.slice(0, 32) === "https://www.youtube.com/watch?v="
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isLink = () => {
    if (
      addCourse.topicUrl &&
      (addCourse.topicUrl.slice(0, 8) === "https://" ||
        addCourse.topicUrl.slice(0, 8) === "https:\\\\")
    ) {
      return true;
    }
    return false;
  };

  const isDisplayble = () => {
    if (isImage() && linkData && linkData.description !== undefined) {
      return true;
    } else {
      return false;
    }
  };
  const isSubmittable = () => {
    if (addCourse.topicType === "article") {
      return isDisplayble() && isLink() && !isVideo();
    } else if (addCourse.topicType === "videos") {
      // return isDisplayble() && isLink() && isVideo();
      return isVideo();
    }
    return false;
  };
  // console.log("Is Video >>>>", isVideo());

  // console.log("Is Link >>>>", isLink());

  // console.log("Is Displayble >>>>", isDisplayble());

  // console.log("Topic Type >>> ", addCourse.topicType);

  // console.log("Link Data Content >>> ", linkData);

  return (
    <div>
      <div>
        <img
          src={linkData && linkData.images.length > 0 && linkData.images[0]}
          alt="checkImage"
          onLoad={handleOnLoad}
          onError={handleOnError}
          width="2"
          height="2"
          style={{ display: "none" }}
        />
      </div>
      <div>
        <h2 style={{ fontWeight: "bold" }}>Add New Library Content</h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_library">Manage Library</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add New Library Content</Breadcrumb.Item>
      </Breadcrumb>
      <NewCourseWrapper>
        <Form onFinish={handleSubmit}>
          <Row align="middle" style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Title of Topic:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.topicName}
                name="coursTitle"
                rules={[
                  { required: true, message: "Please enter title of Topic!" },
                ]}
              >
                <InputWrapper
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, topicName: e.target.value })
                  }
                  placeholder="Title of Topic"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Description:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.description}
                name="Description"
                rules={[
                  { required: true, message: "Please enter Description!" },
                ]}
              >
                <TextAreaWrapper
                  showCount
                  maxLength={700}
                  rows={8}
                  onChange={(e) =>
                    setAddCourse({ ...addCourse, description: e.target.value })
                  }
                  placeholder="Description"
                />
              </Form.Item>
              {/* <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  bottom: 25,
                }}
              >
                <span>{addCourse?.description.length}/700</span>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>#Tags:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                initialValue={addCourse.week}
                name="tags"
                rules={[{ required: true, message: "Please enter any #Tags!" }]}
              >
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Tags Mode"
                  onChange={handleChange}
                >
                  {allCategories.category &&
                    allCategories.category.map((item, index) => {
                      return (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <p style={{ color: "red", marginBottom: 0 }}>{error}</p>
            </Col>
          </Row>
          <Row align="middle">
            <Col span={6} offset={6}>
              <RadioWrapper
                value={addCourse.topicType}
                onChange={(e) =>
                  setAddCourse({ ...addCourse, topicType: e.target.value })
                }
              >
                <Radio
                  defaultChecked={true}
                  style={{ marginRight: "25px" }}
                  value={"article"}
                  onClick={()=>{setIsArticle(true)}}
                >
                  Articles
                </Radio>
                <Radio onClick={()=>{setIsArticle(false)}} value={"videos"}>Videos</Radio>
              </RadioWrapper>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ margin: 0 }}
                initialValue={addCourse.topicUrl}
                // name="link"
                // rules={[{ required: true, message: "Please input Link!" }]}
              >
                <InputWrapper
                  onChange={async (e) => {
                    setWait(true);
                    setAddCourse({ ...addCourse, topicUrl: e.target.value });
                    await getLinkPreview(
                      `https://secret-ocean-49799.herokuapp.com/${e.target.value}`
                    ).then((data) => {
                      setLinkData(data);
                      setWait(false);
                    });
                  }}
                  placeholder={isArticle == true ? "https://www.google.com/" : "https://www.youtube.com/"}
                />
                {addCourse.topicUrl.length !== 0 && !isSubmittable() ? (
                  <p style={{ marginTop: "3px", color: "#f00" }}>
                    Please enter valid link!
                    <Tooltip title="The link you provided is invalid, the emerge data could not be generated. To enable this, please input a valid link that could provide with the sufficient data.">
                      <InfoCircleOutlined style={{ marginLeft: "2px" }} />
                    </Tooltip>
                  </p>
                ) : (
                  ""
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 20 }}>
            <Col offset={18} style={{ marginRight: 10 }}>
              <StyledButton
                style={{ background: "#2A2A2A" }}
                onClick={() => history.push("/manage_library")}
              >
                Cancel
              </StyledButton>
            </Col>
            <Col>
              <StyledButton
                disabled={!isSubmittable() || addCourse.topicUrl === ""}
                htmlType="submit"
                loading={
                  addCourse.topicUrl !== "" && isLink() && (loading || wait)
                }
              >
                Save
              </StyledButton>
            </Col>
          </Row>
        </Form>
      </NewCourseWrapper>
    </div>
  );
};

export default connect((state) => {
  return {
    //   tableData: state.getAllCourses.AllCourses,
    garbage: state.getAllSalon.garbage,
  };
})(AddNewLibraryContent);
