import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Input } from "antd";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const ParentsInfo = (props) => {
  const previous = (e) => {
    props.prevStep();
  };

  const next = (e) => {
    props.nextStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a" onFinish={next}>
          <h3 style={{ textAlign: "center", margin: "20px " }}>
            In case we need to contact your guardians
          </h3>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "47%" }}>
              <h4 style={{ textAlign: "center" }}>
                Primary Guardian(s) details
              </h4>
              <p style={{ marginBottom: 0 }}>Primary guardians First Name:</p>
              <Form.Item
                initialValue={props.infoState.primeGuardianFirstName}
                name="priGuardianFirstName"
              >
                <InputWrapper
                  disabled
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians First Name*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      primeGuardianFirstName:
                        props.infoState.primeGuardianFirstName,
                    })
                  }
                />
              </Form.Item>

              <p style={{ marginBottom: 0 }}>Primary guardians Last Name:</p>
              <Form.Item
                initialValue={props.infoState.primeGuardianLastName}
                name="priGuardianLastName"
              >
                <InputWrapper
                  disabled
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Last Name*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      primeGuardianLastName:
                        props.infoState.primeGuardianLastName,
                    })
                  }
                />
              </Form.Item>
              <p style={{ marginBottom: 0 }}>Primary guardians Email:</p>
              <Form.Item
                initialValue={props.infoState.primeGuardianEmail}
                name="priGuardianEmail"
              >
                <InputWrapper
                  disabled
                  prefix={<MailOutlined style={{ marginRight: 5 }} />}
                  placeholder="Guardians Email*"
                  onChange={(e) =>
                    props.setInfoState({
                      ...props.infoState,
                      primeGuardianEmail: props.infoState.primeGuardianEmail,
                    })
                  }
                />
              </Form.Item>
            </div>

            <div style={{ width: "47%" }}>
              <h4 style={{ textAlign: "center" }}>
                Secondary Guardian(s) details{" "}
                <span style={{ fontSize: 12 }}>(if applicable)</span>
              </h4>

              <p style={{ marginBottom: 0 }}>Second guardians First Name:</p>
              <Form.Item value={props.infoState.secGuardianFirstName}>
                <InputWrapper
                  disabled
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianFirstName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                />
              </Form.Item>

              <p style={{ marginBottom: 0 }}>Second guardians Last Name:</p>
              <Form.Item initialValue={props.infoState.secGuardianLastName}>
                <InputWrapper
                  disabled
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianLastName}
                  prefix={<UserOutlined style={{ marginRight: 5 }} />}
                />
              </Form.Item>

              <p style={{ marginBottom: 0 }}>Second guardians Email:</p>
              <Form.Item initialValue={props.infoState.secGuardianEmail}>
                <InputWrapper
                  disabled
                  style={{ width: "100%" }}
                  value={props.infoState.secGuardianEmail}
                  prefix={<MailOutlined style={{ marginRight: 5 }} />}
                />
              </Form.Item>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            <Button size="large" htmlType="submit" type="primary">
              Next{" "}
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default ParentsInfo;

const InputWrapper = styled(Input)`
  &.ant-input-affix-wrapper-disabled .ant-input[disabled] {
    color: #666666;
  }
`;
