import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import TableWrapper from "../../components/react-table";
import SwitchWrapper from "../../components/switch/index";
import { connect } from "react-redux";
import { TableButton } from "../../components/styled-component/styledButton";
import { CHANGE_LIBRARY_STATUS } from "../../query/Mutations";
import { GET_ALL_LIBRARIES } from "../../query/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Collapse, Select, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { message } from "antd";

const { Panel } = Collapse;
const { Option } = Select;

const ManageLibrary = (props) => {
  let history = useHistory();
  const [itemId, setItemId] = useState("");

  const handleAddNewCourse = () => {
    history.push("/add_library_content");
  };
  const [tableButtonStyle, setTableButtonStyle] = useState({});
  // // ==============Fetch all library=============
  const { data: librariesData, loading: loadingLibraries } = useQuery(
    GET_ALL_LIBRARIES,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        props.dispatch({
          type: "GET_ALL_LIBRARIES",
          payload: librariesData.getAllLibraries,
        });
      },
      // onError: (e) => setQueryError({ occur: true, error: e }),
    }
  );

  // =============seccess added liberary message=============
  useEffect(() => {
    if (props.tableData.length < librariesData?.getAllLibraries.length) {
      message.success("Topic successfully added in library section");
    }
  }, [props.tableData.length, librariesData]);

  // Table Filter
  const [data, setData] = useState([]);
  const [textbox, setTextbox] = useState("");
  const [selectbox, setSelectbox] = useState("");

  const textboxSearch = (e) => {
    setTextbox(e.target.value);
  };

  const selectboxSearch = (value) => {
    setSelectbox(value);
  };

  const textSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      return value.topicName.toLowerCase().includes(textbox.toLowerCase());
    });
    setData(filteredData);
  };

  const selectSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      return value.topicType.toLowerCase().includes(selectbox.toLowerCase());
    });
    setData(filteredData);
  };

  useEffect(() => textSearch(), [textbox]);
  useEffect(() => selectSearch(), [selectbox]);
  useEffect(() => setData(props.tableData), [props.tableData]);

  // ========== Handle Type============
  const handleTopicType = (r) => {
    window.open(`${r.original.topicUrl}`, "_blank");
    setTableButtonStyle({
      border: "1px solid #2A2A2A",
    });
  };

  // ======change status =============
  const [changeLibraryStatus, { loading: itemLoading }] = useMutation(
    CHANGE_LIBRARY_STATUS
  );
  const handleChange = async (e, id) => {
    try {
      const res = await changeLibraryStatus({
        variables: {
          libraryId: id,
        },
      });
      props.dispatch({
        type: "CHANGE_LIBRARY_STATUS",
        payload: { LibraryStatus: res.data.changeLibraryStatus.status, id: id },
      });
      console.log(res, "res in change library status");
    } catch (error) {
      console.log(error, "error in library status");
    }
  };

  return (
    <div>
      <ContentHeader
        title="Manage Library"
        handleTextSearch={textboxSearch}
        handleSelectSearch={selectboxSearch}
        handleAddNewCourse={handleAddNewCourse}
        count={props.tableData ? props.tableData.length : 0}
        options={[
          { label: "View All", value: "" },
          { label: "Articles", value: "article" },
          { label: "Videos", value: "videos" },
        ]}
      />
      <TableWrapper
        tableData={data}
        loading={props.loading}
        pageSize={5}
        enableReInit={true}
        content={[
          {
            name: "Topic Name",
            id: "topicName",
            small: true,
            sortable: false,
            render: (r) => {
              return (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Collapse
                    style={{ width: "100%" }}
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={r.original.topicName}
                      key={r.original._id}
                      extra={
                        null
                        // <div
                        //   style={{
                        //     display: "flex",
                        //     alignItems: "center",
                        //     justifyContent: "space-between",
                        //   }}
                        // >
                        //   <div>
                        //     <SwitchWrapper
                        //       key={r.original._id}
                        //       loading={itemId === r.original._id && itemLoading}
                        //       defaultChecked={
                        //         r.original.status === true ? true : false
                        //       }
                        //       style={{ marginRight: 10 }}
                        //       onChange={(event) => {
                        //         setItemId(r.original._id);
                        //         handleChange(event, r.original._id);
                        //       }}
                        //     ></SwitchWrapper>
                        //     {r.original.status === true
                        //       ? "Active"
                        //       : "De-Active"}
                        //   </div>
                        // </div>
                      }
                    >
                      <h2>Title:</h2>
                      <p>{r.original.topicName}</p>
                      <h2>Description:</h2>
                      <p>{r.original.description}</p>
                      <h2>Article or Video Link:</h2>
                      <Typography.Link
                        href={r.original.topicUrl}
                        target="_blank"
                      >
                        {r.original.topicUrl}
                      </Typography.Link>
                    </Panel>
                  </Collapse>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "130px",
                      margin: "0 10px",
                    }}
                  >
                    <div>
                      <SwitchWrapper
                        key={r.original._id}
                        loading={itemId === r.original._id && itemLoading}
                        defaultChecked={
                          r.original.status === true ? true : false
                        }
                        style={{ marginRight: 10 }}
                        onChange={(event) => {
                          setItemId(r.original._id);
                          handleChange(event, r.original._id);
                        }}
                      ></SwitchWrapper>
                      {r.original.status === true ? "Active" : "De-Active"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 1%",
                    }}
                  >
                    <div>
                      {r.original.status ? (
                        <TableButton
                          onClick={(event) => {
                            handleTopicType(r);
                            event.stopPropagation();
                          }}
                          style={tableButtonStyle}
                        >
                          {r.original.topicType === "article"
                            ? "Article"
                            : "Video"}
                        </TableButton>
                      ) : (
                        <TableButton
                          onClick={(event) => {
                            handleTopicType(r);
                            event.stopPropagation();
                          }}
                          style={tableButtonStyle}
                          disabled
                        >
                          {r.original.topicType === "article"
                            ? "Article"
                            : "Video"}
                        </TableButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    tableData: state.getAllLibraries.AllLibraries,
    garbage: state.getAllLibraries.garbage,
  };
})(ManageLibrary);
