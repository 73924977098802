import ApolloClient from "apollo-client";
// import ApolloClient from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getCookie } from "../helper";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ADMIN_URL,
});

const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem("token");
  const token = getCookie("token");
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    },
  };
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client };
