import React from "react";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Select, Input } from "antd";
import QueueAnim from "rc-queue-anim";
const { TextArea } = Input;

const QuestionOne = (props) => {
  console.log(props, "props in one question");
  return (
    <div style={{ width: "80%", marginBottom: 20 }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a" onFinish={() => props.nextStep()}>
          <h3 style={{ margin: "20px 0" }}>
            {props.data && props.data.questionId.question}
          </h3>
          <h6 style={{ margin: "20px 0", color: "gray" }}>
            {props.data && props.data.discription}
          </h6>

          <div style={{ height: 150, overflowY: "scroll" }}>
            <p style={{ color: "gray", fontSize: 16 }}>
              {" "}
              {props.data && props.data.answer}
            </p>
          </div>

          {/* <Form.Item 
            initialValue={props.data.answer}
            name="firstName"
            rules={[{ required: true, message: 'Please input your Answer!' }]}
            >
                <TextArea
                    disabled
                    style={{minHeight: 150, color: 'gray'}}
                    value={props.applicationState.questionOne}
                    name='name'
                    type='text'
                    placeholder="Tesla, because I love the technology they are about."
                />
            </Form.Item> */}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button size="large" htmlType="submit" type="primary">
              {" "}
              Next
            </Button>
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default QuestionOne;
