import React from "react";
import { Card, Icon, Avatar } from "antd";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-top: 20px;
  width: 100%;
  background: white;
  padding: 15px;
  box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.20);
  border: none;
  color: black;
  border-radius: 10;
  `
const NewCourseWrapper = (props) => {
  return <StyledCard {...props}>{props.children}</StyledCard>;
};
export default NewCourseWrapper;
