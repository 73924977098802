import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;
const StyledInput = styled(TextArea)`
  border: 1px solid gray !important;
  // color: #49d3ce;
  box-shadow: #49d3ce;
  border-radius: 6px;
  //height: 200px;
  // resize: none;
  .textarea.ant-input {
    height: 200px !important;
  }
  resize: none;
`;

const TextAreaWrapper = (props) => {
  return <StyledInput {...props}></StyledInput>;
};

export default TextAreaWrapper;
