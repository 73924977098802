let g = 0;

const initialState = {
  status: [],
};

const userStatus = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_STATUS":
      return { ...state, status: action.payload };

    case "USER_ACCEPTED_STATUS":
      const userStatusFilter = state.status.find(
        (item) => item._id === action.payload._id
      );
      userStatusFilter.status = action.payload.status;
      return { ...state, status: state.status, garbage: g++ };

    case "USER_DECLINE_STATUS":
      const courseDeclineStatus = state.status.find((item) => {
        return item._id === action.payload._id;
      });
      courseDeclineStatus.status = action.payload.status;
      return { ...state, status: state.status, garbage: g++ };

    default:
      return state;
  }
};

export default userStatus;
