import React, { useState } from "react";
import { Row, Col } from "antd";
import Avatar from "../avatar/index";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import {
  StyledButton,
  TableButton,
} from "../../components/styled-component/styledButton";
import adminAvatar from "../../images/admin.png";
import { useHistory, Link } from "react-router-dom";
import { useMutation } from "react-apollo";
import { LOGOUT_QUERY } from "../../query/Mutations";
import { getCookie, removeCookie } from "../../helper";

const HeaderContent = (props) => {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState("none");

  const [logout, { loading }] = useMutation(LOGOUT_QUERY);

  const handleLogout = async () => {
    try {
      logout({
        variables: {
          // deviceId: localStorage.getItem("DeviceId"),
          deviceId: getCookie("DeviceId"),
        },
      });
      setTimeout(() => {
        // localStorage.clear();
        removeCookie("loggedIn");
        removeCookie("DeviceId");
        removeCookie("token");
        props.dispatch({ type: "LOGOUT" });
        history.push("/");
      }, 2000);
    } catch (error) {
      throw new Error(error);
    }
  };

  //-----------------------------//
  //   Decrypting User Role      //
  //-----------------------------//
  // const bytes_role = CryptoJS.AES.decrypt(
  //   localStorage.getItem("role") || "",
  //   key || "zingeratha"
  // );
  // const decrypted_role = bytes_role.toString(CryptoJS.enc.Utf8);

  return (
    <div>
      <Row span={24}>
        <Col span={14}>
          <button
            onClick={props.toggleCollapsed}
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
          >
            {props.state.collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: 24, color: "#1D194D" }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: 24, color: "#1D194D" }} />
            )}
          </button>
        </Col>
        <Row style={{ position: "fixed", right: 90 }}>
          {/* <Col>
            {decrypted_role === "2" ? (
              <Link to="/add-new-admin">
                <TableButton
                  style={{
                    borderRadius: 6,
                    fontWeight: "bolder",
                  }}
                >
                  Create Sub Admin
                </TableButton>
              </Link>
            ) : null}
          </Col> */}
          <Col>
            <span style={{ margin: "0 20px 0 30px" }}>Hello Admin</span>
          </Col>
          <Col>
            <Avatar
              shape="circle"
              size="large"
              icon="user"
              style={{ color: "white" }}
              src={adminAvatar}
              onClick={() =>
                showMenu == "none"
                  ? setShowMenu("inline-flex")
                  : setShowMenu("none")
              }
            />
          </Col>
          <Col style={{ marginLeft: 16 }}>
            <StyledButton onClick={handleLogout} loading={loading}>
              Logout
            </StyledButton>
          </Col>
        </Row>
        {/*   <Badge dot style={{ color: "white" }}>
                  <PopoverWrapper title={"Account"}>
                    <Avatar
                      shape="circle"
                      size="large"
                      icon="user"
                      style={{ color: "white" }}
                      src={goku}
                    />
                  </PopoverWrapper>
                </Badge> */}
      </Row>
    </div>
  );
};

export default HeaderContent;
