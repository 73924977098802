import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, Skeleton } from "antd";
import UserStatCard from "../../appcomponents/dashboard/UserStatCard";
import UserLibraryCard from "../../appcomponents/dashboard/UserLibraryCard";
import { FaUsers, FaReadme, FaAward } from "react-icons/fa";
import loginTopImage from "../../images/loginTopImage.png";

const Dashboard = (props) => {
  const { allUser, allLibraries, allCourses } = props;
  console.log(allCourses, "allCourses");

  // const [totalStudents, setTotalStudents] = useState(0);
  // const [activeCustomer, setAvtiveCustomer] = useState(0);
  // const [activeSalon, setAvtiveSalon] = useState(0);
  const libraryAllVideo = [];
  const libraryAllArticle = [];
  const totalStudents = [];

  allLibraries.map((item) => {
    if (item.topicType === "article") {
      libraryAllArticle.push(item);
    } else {
      libraryAllVideo.push(item);
    }
  });

  allUser.map((item) => {
    if (item.applicationStatus === "approved") {
      totalStudents.push(item);
    }
  });

  return (
    <React.Fragment>
      <h1 style={{ textAlign: "left", fontWeight: "bold" }}>Dashboard</h1>
      <Row
        justify="space-between"
        type="flex"
        style={{
          textAlign: "left",
          align: "center",
        }}
      >
        <Col className="mainContentDiv" span={7}>
          <UserLibraryCard
            img={loginTopImage}
            loading={allLibraries.length < 1 ? true : false}
            title="Active Customers"
            libraryAllVideo={libraryAllVideo && libraryAllVideo}
            libraryAllArticle={libraryAllArticle && libraryAllArticle}
          />
        </Col>
        <Col span={16}>
          <Row type="flex" justify="space-between">
            <Col className="mainContentDiv" span={11}>
              <UserStatCard
                loading={allUser.length < 1 ? true : false}
                icon={<FaUsers />}
                title="Total Applications"
                amount={allUser}
              />
            </Col>
            <Col className="mainContentDiv" span={11}>
              <UserStatCard
                loading={allUser.length < 1 ? true : false}
                icon={<FaUsers />}
                title="Total Students"
                amount={totalStudents && totalStudents}
              />
            </Col>
            <Col className="mainContentDiv" span={11}>
              <UserStatCard
                loading={allCourses.length < 1 ? true : false}
                icon={<FaReadme />}
                title="No. of Courses"
                amount={allCourses}
              />
            </Col>
            <Col className="mainContentDiv" span={11}>
              <UserStatCard
                // loading={loading}
                icon={<FaAward />}
                title="Certificates Awarded"
                amount={""}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "states in dashboard");
  return {
    allUser: state.getAllUsers.AllUsers,
    allLibraries: state.getAllLibraries.AllLibraries,
    allCourses: state.getAllCourses.AllCourses,
  };
};

export default connect(mapStateToProps)(Dashboard);
