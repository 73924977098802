import React from "react";
import { Button, Modal } from "antd";
import { useMutation } from "react-apollo";
// import { LOGOUT_QUERY } from "../../query/Mutations";
import { getCookie, removeCookie } from "../../helper";
import { useHistory, Link } from "react-router-dom";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: grid;
`;

const LogoutButton = styled(Button)`
  width: 120px;
  margin: 20px;
  background-color: #474895;
  color: white;
  border: none;
  &:hover {
    border: 1px solid #474895;
    color: #474895;
  }
`;

const LogoutDiv = styled.div`
  text-align: center;
`;

export default (props) => {
  const handleLogout = () => {
    removeCookie("token");
    removeCookie("DeviceId");
    removeCookie("loggedIn");
    window.location.reload();
  };
  return (
    <Modal
      title={props.title}
      visible={props.visible}
      // onCancel={() => props.onCancel(false)}
      footer={null}
    >
      <StyledDiv>
        {props.errorMessage !== undefined &&
        (props.errorMessage.toString().includes("Error: GraphQL error") ||
          props.errorMessage.toString().includes("Error: Network error"))
          ? props.errorMessage !== undefined &&
            props.errorMessage.toString().slice(21)
          : props.errorMessage !== undefined && props.errorMessage.toString()}
        <LogoutDiv>
          <LogoutButton onClick={handleLogout}>LogOut</LogoutButton>
        </LogoutDiv>
      </StyledDiv>
    </Modal>
  );
};
