import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import { connect } from "react-redux";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  Collapse,
  Button,
  Row,
  Col,
  Radio,
  Form,
  Breadcrumb,
  Input,
  Alert,
  message,
} from "antd";
import NewCourseWrapper from "../../components/newCourse/NewCourseWrapper";
import RadioWrapper from "../../components/radioButton/Index";
import InputWrapper from "../../components/input/index";
import { useHistory, Link } from "react-router-dom";
import { ADD_TEST_QUESTION } from "../../query/Mutations";
import { GET_TEST_QUESTIONS } from "../../query/Queries";
import TestQuestionWrapper from "../../components/TestQuestionWrapper";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import {
  NoSchemaIntrospectionCustomRule,
  UniqueFieldDefinitionNamesRule,
} from "graphql";
import uuid from "react-uuid";
import "../../App.css";
import { getCookie } from "../../helper";

const CreateQuize = (props) => {
  const history = useHistory();
  // let courseName = localStorage.getItem("courseName");
  // const courseId = localStorage.getItem("courseId");
  let courseName = getCookie("courseName");
  const courseId = getCookie("courseId");
  const { topicName, week, _id } = props.location.state;
  const [topicQuestion, setTopicQuestion] = useState([]);
  const [optionError, setOptionError] = useState("");
  const [testQuestion, setTestQuestion] = useState({
    question: "",
    choices: [],
    correctAnswer: "",
  });
  console.log("course id: ", _id);
  // ============Get Test Questions Query==========

  const { data, loading: testQuestionLoading, error } = useQuery(
    GET_TEST_QUESTIONS,
    {
      variables: { courseId: _id, week: week },
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        setTopicQuestion(data.getTestQuestions);
      },
      onError: () => {
        console.log(error);
      },
    }
  );
  console.log("Correct Answer: ", testQuestion.correctAnswer);

  const [form] = Form.useForm();
  console.log(topicQuestion, "topicQuestion >>>>");

  // ===============Add Test Questions Mutation============
  const [addTestQuestion, { loading }] = useMutation(ADD_TEST_QUESTION);
  console.log("TestQuestion", testQuestion);
  const handleSubmit = async (e) => {
    for (var option of options) {
      testQuestion.choices.push(option);
    }
    console.log("Choice length >>>>", testQuestion.correctAnswer.length);
    if (testQuestion.correctAnswer.length > 0) {
      topicQuestion.push(testQuestion);
      try {
        const res = await addTestQuestion({
          variables: {
            question: testQuestion.question,
            courseId: _id,
            week: week,
            choices: testQuestion.choices,
            correctAnswer: testQuestion.correctAnswer,
          },
        });
        message.success("Question added successfully", 0.8);
        if (res.data.addTestQuestion) {
          setTestQuestion({
            ...testQuestion,
            question: "",
            choices: [],
            correctAnswer: "",
          });
          form.resetFields();
          clearOptions();
          resetOptionFields();
          setOptionError(null);
          console.log("Testchoice", testQuestion.choices);
          // getTestQuestions({variables: {courseId: courseId, week: week}})
        }
        // console.log(e.target.name);

        console.log(res, "res add quize");
      } catch (error) {
        console.log(error);
      }
    } else {
      setTestQuestion({
        ...testQuestion,
        choices: [],
        correctAnswer: "",
      });
      setOptionError("Please fill correct choice first");
      return 0;
    }
    console.log(testQuestion);
  };

  //==================Creating Dynamic Options inputs=====================

  const [optionFields, setOptionFields] = useState([
    {
      id: uuid(),
      optionText: "",
    },
    {
      id: uuid(),
      optionText: "",
    },
  ]);
  const resetOptionFields = () => {
    for (var item of optionFields) {
      optionFields.pop();
    }
    setOptionFields([
      {
        id: uuid(),
        optionText: "",
      },
      {
        id: uuid(),
        optionText: "",
      },
    ]);
  };

  const handleAddOptions = () => {
    setOptionFields([...optionFields, { id: uuid(), optionText: "" }]);
    setTimeout(() => {
      removeInput();
    }, 50);
  };

  const handleOptionChange = (id, event) => {
    var newOptionFields = optionFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setOptionFields(newOptionFields);
  };
  const handleRemoveOptions = (id) => {
    const values = [...optionFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setOptionFields(values);
    setTestQuestion({ ...testQuestion, correctAnswer: "" });
  };

  const handleQuestionChange = (event) => {
    const { value } = event.target;
    setTestQuestion({ ...testQuestion, question: value });
  };

  const clearOptions = () => {
    const resetOptionFields = optionFields.map((i) => {
      i.optionText = "";

      return i;
    });
    setOptionFields(resetOptionFields);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setTestQuestion({ ...testQuestion, correctAnswer: e.target.value });
  };
  var options = optionFields.map((optionField) => optionField.optionText);
  // console.log(options);
  console.log("Option Fields>>>", optionFields);

  const removeInput = () => {
    const testInput = document.getElementsByClassName("form-input");
    console.log("Option Inputs >>> ", testInput);
    testInput[testInput.length - 1].value = "";
  };

  return (
    <div>
      <div>
        <h2 style={{ fontWeight: "bold" }}>Create Quiz</h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_course">{courseName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/course_content_details">{topicName}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Quiz</Breadcrumb.Item>
      </Breadcrumb>
      <NewCourseWrapper>
        <TestQuestionWrapper
          data={topicQuestion}
          loading={testQuestionLoading}
        />

        <Form onFinish={handleSubmit} form={form}>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Question:</p>
            </Col>
            <Col span={18}>
              <Form.Item
                // initialValue={addCourse.title}
                name="coursTitle"
                rules={[{ required: true, message: "Please input question" }]}
              >
                <InputWrapper
                  value={testQuestion.question}
                  onChange={(e) => handleQuestionChange(e)}
                  name="questionText"
                  placeholder="Question"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ margin: "20px 0" }}>
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Options:</p>
              <div
                style={{
                  marginTop: "5%",
                }}
              >
                <span>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<PlusOutlined />}
                    style={{
                      background: "#12D82E",
                      border: "none",
                      display: optionFields.length < 10 ? "inline" : "none",
                    }}
                    onClick={handleAddOptions}
                    className="addsub-button"
                  />
                </span>

                <span style={{ margin: "0 10px" }}>
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<MinusOutlined />}
                    style={{
                      background: "#E23715",
                      border: "none",
                      display: optionFields.length > 2 ? "inline" : "none",
                    }}
                    onClick={handleRemoveOptions}
                    className="addsub-button"
                  />
                </span>
              </div>
            </Col>

            <Col span={18}>
              <div
                style={{
                  width: "100%",
                  display: "inline-grid",
                  gridTemplateColumns: "auto auto",
                }}
              >
                {optionFields.map((optionField) => (
                  <div key={optionField.id} style={{ marginLeft: "10px" }}>
                    <Form.Item
                      name={`Option-${optionFields.indexOf(optionField)}`}
                      label={`Option-${optionFields.indexOf(optionField) + 1}`}
                      // initialValue={addCourse.title}
                      rules={[
                        {
                          required: true,
                          message: `Please input Option-${optionFields.indexOf(
                            optionField
                          ) + 1}`,
                        },
                      ]}
                    >
                      <Input
                        style={{
                          borderRadius: "6px",
                          boxShadow: "#49d3ce",
                          borderColor: "gray !important",
                          outline: "none !important",
                        }}
                        className="form-input"
                        name="optionText"
                        placeholder={`Option-${optionFields.indexOf(
                          optionField
                        ) + 1}`}
                        onChange={(event) =>
                          handleOptionChange(optionField.id, event)
                        }
                        value={optionField.optionText}
                      />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
          </Row>

          <Row align="middle">
            <Col span={6}>
              <p style={{ marginBottom: 0 }}>Choice Correct Option:</p>
            </Col>
            <Col span={18}>
              <Radio.Group
                onChange={onChange}
                value={testQuestion.correctAnswer}
              >
                {optionFields.map((optionField) => (
                  <div
                    style={{
                      display: "inline-grid",
                      gridTemplateColumns: "auto auto auto auto",
                    }}
                  >
                    <div
                      style={{
                        margin: "0 0 20px 100px",
                      }}
                    >
                      <Radio
                        defaultChecked="none"
                        value={optionField.optionText}
                        style={{
                          display:
                            optionField.optionText.length > 0
                              ? "inline"
                              : "none",
                        }}
                      >
                        {`${optionFields.indexOf(optionField) + 1}`}
                      </Radio>
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              {optionError ? (
                <Alert
                  style={{ display: "flex", justifyContent: "center" }}
                  message="Please add correct choice"
                  type="error"
                />
              ) : null}
            </Col>
            <Col span={19}>
              <Row
                justify="end"
                style={{ marginTop: 20, display: "flex", alignItems: "center" }}
              >
                <Col
                  span={3}
                  style={{
                    marginRight: 10,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <StyledButton
                    style={{
                      background: "#2A2A2A",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={() => history.push("/course_content_details")}
                  >
                    Cancel
                  </StyledButton>
                </Col>
                <Col span={3}>
                  <StyledButton
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </NewCourseWrapper>
    </div>
  );
};

export default connect((state) => {
  return {
    tableData: state.getAllCourses.AllCourses,
    garbage: state.getAllSalon.garbage,
  };
})(CreateQuize);
