import React from "react";
import { Route, Switch } from "react-router-dom";

// Register and Login Screen
import LoginNew from "./views/LoginNew";
import Shell from "./views/shell/Shell";
import { connect } from "react-redux";
import { getCookie } from "./helper";

class Routes extends React.Component {
  render() {
    return (
      <div className="App">
        <Switch>
          <Route path="/">
            {/* {localStorage.getItem("loggedIn") === "true" &&
            localStorage.getItem("token") !== "" ? ( */}
            {getCookie("loggedIn") === "true" && getCookie("token") !== "" ? (
              <Shell dispatch={this.props.dispatch} />
            ) : (
              <LoginNew />
            )}
          </Route>

          {/* <Route path='*' exact={true} component={NotFound} /> */}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.isLoggedIn ? state.isLoggedIn.isLoggedin : false,
});

export default connect(mapStateToProps)(Routes);
