let g = 0;
const initialState = {
  AllLibraries: [],
};

const getAllLibraries = (state = initialState, action) => {
  // console.log(action.payload, "action payload");
  switch (action.type) {
    case "GET_ALL_LIBRARIES":
      initialState.AllLibraries = action.payload;
      return Object.assign({}, state, initialState);

    case "ADD_NEW_LIBRARY":
      return {
        ...initialState,
        AllLibraries: [action.payload, ...initialState.AllLibraries],
        garbage: g++,
      };

    case "CHANGE_LIBRARY_STATUS":
      const LibraryStatus = initialState.AllLibraries.find(
        (item) => item._id === action.payload.id
      );
      LibraryStatus.status = action.payload.LibraryStatus;

      return {
        ...state,
        AllLibraries: initialState.AllLibraries,
        garbage: g++,
      };

    default:
      return state;
  }
};

export default getAllLibraries;
