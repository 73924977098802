import React, { useState, useEffect } from "react";
import { Tabs, Button, Modal, Spin, Row, Col } from "antd";
import styled from "styled-components";
import StudentInfoFormContainer from "./studentInformation/StudentInfoFormContainer";
import StudentApplicationFormContainer from "./applicationQuestions/StudentApplicationFormContainer";
import ApplicationNavbar from "./applicationQuestions/ApplicationNavbar";
import InfoNavbar from "./studentInformation/InfoNavbar";
import { useQuery } from "@apollo/client";
import { GET_USER_APP_ANSWER } from "../../query/Queries";

const { TabPane } = Tabs;

const UserDetailModal = (props) => {
  const [appAnswer, setAppAnswer] = useState("");
  const [step, setStep] = useState(1);
  const [step1, setStep1] = useState(1);

  const { data, loading, error } = useQuery(GET_USER_APP_ANSWER, {
    variables: { userId: props.data._id },
    onCompleted: () => {
      setAppAnswer(data.getUserAppAnswers);
    },
    onError: () => {
      console.log(error, "error in app answer");
    },
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep1 = () => {
    setStep1(step1 + 1);
  };

  const prevStep1 = () => {
    setStep1(step1 - 1);
  };

  return (
    <>
      <ModalWrapper
        width={900}
        visible={props.modal}
        onOk={() => props.setModal(false)}
        footer={[
          <Button type="primary" onClick={() => props.setModal(false)}>
            Ok
          </Button>,
        ]}
      >
        <TabsNav defaultActiveKey="1">
          <TabPane tab={<span>Student Info</span>} key="1" forceRender={true}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 450,
                flexDirection: "column",
                padding: "20px 30px 20px 30px",
              }}
            >
              <InfoNavbar step={step} setStep={setStep} />
              <StudentInfoFormContainer
                step={step}
                nextStep={nextStep}
                prevStep={prevStep}
                data={props.data}
              />
            </div>
          </TabPane>

          <TabPane
            forceRender={true}
            tab={<span>Student Applicaton Q/A</span>}
            key="2"
            onChange={() => alert("adf")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 350,
                flexDirection: "column",
                padding: "20px 30px 20px 30px",
              }}
            >
              {loading ? (
                <Spin size="large" />
              ) : (
                <>
                  <ApplicationNavbar
                    step={step1}
                    setStep={setStep1}
                    // prevStep={prevStep}
                  />
                  <StudentApplicationFormContainer
                    style={{ height: 300 }}
                    step={step1}
                    nextStep={nextStep1}
                    prevStep={prevStep1}
                    data={appAnswer}
                  />
                </>
              )}
            </div>
          </TabPane>
        </TabsNav>
      </ModalWrapper>
    </>
  );
};

export default UserDetailModal;

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
  }
  .ant-modal-body {
    padding: 0;
    border-radius: 12px;
  }
  .ant-modal-close-x {
    display: none;
  }
`;
const TabsNav = styled(Tabs)`
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tab {
    color: #464797;
    padding: 20px 138px;
    font-size: 15px;
    margin: 0;
  }
  .ant-tabs-ink-bar {
    background: #464797 !important;
    height: 3px !important;
  }
`;
