import React from "react";
import { Skeleton, Row, Col } from "antd";
import CardWrapper from "../../components/card";

const UserStatCard = (props) => {
  console.log(props.amount.length, "props.amoutn");
  return (
    <CardWrapper
      className={props.styledClass}
      style={{ borderRadius: 3, padding: "0 8px" }}
    >
      <Skeleton loading={props.loading} avatar active>
        <Row type="flex" justify="space-between" alignItems="center">
          <Col style={{ height: 110 }}>
            <p style={{ fontWeight: 450 }}>{props.title}</p>
            <p style={{ fontWeight: "bolder", fontSize: 16 }}>
              {props.amount.length}
            </p>
          </Col>

          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                width: 50,
                height: 50,
                fontSize: "1.3rem",
                borderRadius: 50,
                backgroundColor: "#464797",
              }}
            >
              {props.icon}
            </div>
          </Col>
        </Row>
      </Skeleton>
    </CardWrapper>
  );
};

export default UserStatCard;
