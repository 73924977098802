import gql from "graphql-tag";

export const GET_ALL_USERS = gql`
  query getAllUsers {
    users {
      _id
      firstName
      lastName
      city
      email
      status
      profileStatus
      applicationStatus
      gender
      birthday
      phone
      isPaid
      bio
      grade
      institute
      primeGuardianFirstName
      primeGuardianLastName
      primeGuardianEmail
      secGuardianFirstName
      secGuardianLastName
      secGuardianEmail
      isJob
      isMaster
      isInter
      knowThrough
      profileLink
    }
  }
`;

export const GET_ALL_COURSES = gql`
  {
    getAllCourses {
      courseName
      description
      courseImageURL
      _id
      createdAt
    }
  }
`;

export const GET_COURSE_DETAIL = gql`
  query abc($courseId: String!) {
    getCourseDetailById(courseId: $courseId) {
      _id
      courseId {
        courseName
      }
      description
      materialUrl
      materialType
      week
      topicName
      status
    }
  }
`;

export const GET_TEST_QUESTIONS = gql`
  query abc($courseId: String!, $week: Float!) {
    getTestQuestions(courseId: $courseId, week: $week) {
      _id
      question
      courseId {
        topicName
        courseId {
          courseName
        }
      }
      week
      choices
      correctAnswer
      status
    }
  }
`;

export const GET_ALL_LIBRARIES = gql`
  {
    getAllLibraries {
      _id
      topicName
      description
      topicUrl
      topicType
      category
      status
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  {
    getAllCategories {
      category
      status
      topicUrl
      _id
    }
  }
`;

export const GET_USER_APP_ANSWER = gql`
  query abc($userId: String!) {
    getUserAppAnswers(userId: $userId) {
      userId {
        firstName
      }
      questionId {
        question
      }
      answer
    }
  }
`;

export const GET_REGISTERED_APPLICANTS = gql`
  query abc($courseId: String!) {
    getRegisteredApplicants(courseId: $courseId) {
      _id
      userId {
        _id
        firstName
        email
      }
      courseId {
        _id
        courseName
      }
      status
      completionStatus
      createdAt
    }
  }
`;

export const GET_USER_COURSE_DETAILS = gql`
  query abc($userId: String!) {
    getUserCourseDetails(userId: $userId) {
      _id
      userId
      courseId {
        _id
        courseName
      }
      status
      createdAt
      weeks {
        _id
        status
        courseId
        week
      }
    }
  }
`;
