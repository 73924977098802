import gql from "graphql-tag";

export const LOGOUT_QUERY = gql`
  mutation abc($deviceId: String!) {
    logout(deviceId: $deviceId) {
      _id
      email
    }
  }
`;

export const USER_LOGIN = gql`
  mutation abc($email: String!, $password: String!, $deviceId: String!) {
    loginUser(email: $email, password: $password, deviceId: $deviceId) {
      _id
      firstName
      email
      jwtToken {
        token
        createdAt
      }
      userRole
    }
  }
`;

export const CHANGE_PAID_STATUS = gql`
  mutation abc($userId: String!) {
    changePaidStatus(userId: $userId) {
      _id
      firstName
      isPaid
    }
  }
`;

export const APPROVED_USER = gql`
  mutation abc($userId: String!) {
    approveUser(userId: $userId) {
      _id
      firstName
      applicationStatus
    }
  }
`;

export const REJECT_USER = gql`
  mutation abc($userId: String!) {
    rejectUser(userId: $userId) {
      _id
      firstName
      applicationStatus
    }
  }
`;

export const ADD_COURSE = gql`
  mutation abc(
    $courseName: String!
    $description: String!
    $courseImageURL: String!
  ) {
    addCourse(
      courseName: $courseName
      description: $description
      courseImageURL: $courseImageURL
    ) {
      courseName
      description
      courseImageURL
    }
  }
`;

export const ADD_COURSE_DETAIL = gql`
  mutation abc(
    $courseId: String!
    $description: String!
    $materialUrl: String!
    $materialType: String!
    $week: Float
    $topicName: String!
  ) {
    addCourseDetail(
      courseId: $courseId
      description: $description
      materialUrl: $materialUrl
      materialType: $materialType
      week: $week
      topicName: $topicName
    ) {
      courseId {
        courseName
      }
      description
      materialUrl
      materialType
      week
      topicName
    }
  }
`;

export const CHANGE_COURSE_DETAIL_STATUS = gql`
  mutation abc($_id: String!) {
    changeCourseDetailStatus(_id: $_id) {
      courseId {
        courseName
      }
      status
      description
      materialUrl
      materialType
      week
      topicName
      createdAt
    }
  }
`;

export const ADD_TEST_QUESTION = gql`
  mutation abc(
    $question: String!
    $courseId: String!
    $week: Float!
    $choices: [String!]
    $correctAnswer: String!
  ) {
    addTestQuestion(
      question: $question
      courseId: $courseId
      week: $week
      choices: $choices
      correctAnswer: $correctAnswer
    ) {
      courseId {
        topicName
        courseId {
          courseName
        }
      }
      question
      week
      choices
      correctAnswer
    }
  }
`;

export const ADD_LIBRARY = gql`
  mutation abc(
    $topicName: String!
    $description: String!
    $topicUrl: String!
    $topicType: String!
    $category: [String!]
  ) {
    addLibrary(
      topicName: $topicName
      description: $description
      topicUrl: $topicUrl
      topicType: $topicType
      category: $category
    ) {
      topicName
      description
      topicUrl
      topicType
      category
    }
  }
`;

export const CHANGE_LIBRARY_STATUS = gql`
  mutation abc($libraryId: String!) {
    changeLibraryStatus(libraryId: $libraryId) {
      _id
      topicName
      status
    }
  }
`;

export const CHANGE_REGISTER_COURSE_STATUS = gql`
  mutation abc($userId: String!, $courseId: String!, $status: String!) {
    changeRegisterCourseStatus(
      userId: $userId
      courseId: $courseId
      status: $status
    ) {
      userId {
        _id
        firstName
      }
      courseId {
        _id
        courseName
      }
      status
      _id
    }
  }
`;
