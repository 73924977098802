let g = 0;
const initialState = {
  AllCourses: [],
};

const getAllCourses = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COURSES":
      initialState.AllCourses = action.payload.reverse();
      return Object.assign({}, state, initialState);

    case "ADD_NEW_COURSE":
      return { 
        ...initialState,
        AllCourses:[action.payload, ...initialState.AllCourses],
        garbage: g++
   }


    default:
      return {
        ...state,
        AllCourses: initialState.AllCourses,
        garbage: g++,
      };
  }
};

export default getAllCourses;
