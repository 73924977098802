import React from "react";
import { Card, Button, Divider } from "antd";
import styled from "styled-components";

const TestQuestionWrapper = (props) => {
  return (
    <QuestionWrapper>
      {props.data &&
        props.data.map((item, index) => {
          return (
            <div style={{ padding: 10, borderRadius: 5 }} key={index}>
              <h2>Question {index + 1}: </h2>
              <h3 style={{ fontWeight: "bold" }}>{item.question}</h3>
              <h2> Choices:</h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto",
                  // justifyContent:
                  //   item.choices.length > 3 ? "space-between" : "center",
                  flexWrap: "wrap",
                }}
              >
                {item.choices &&
                  item.choices.map((item, index) => {
                    return (
                      <div key={index}>
                        <h4
                          style={{
                            width: "fit-content",
                            borderRadius: "5px",
                            // background: "#464696",
                            background: "#fff",
                            boxShadow: "1px 1px 2px 1px #464696",
                            border: "2px solid #464696",

                            margin: "5px 0px 5px 50px",
                            // margin:
                            //   item.choices && item.choices.length > 3
                            //     ? "none"
                            //     : "5px 60px",
                            padding: "10px",
                            fontWeight: "bold",
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <span style={{ color: "#000" }}>{item}</span>
                        </h4>
                      </div>
                    );
                  })}
              </div>
              <h2>Correct Answer:</h2>
              <h4
                style={{
                  borderRadius: 5,
                  border: "2px solid #464696",
                  boxShadow: "1px 1px 2px 1px #464696",
                  background: "#fff",
                  padding: 10,
                  fontWeight: "bold",
                  fontSize: 15,
                  display: item.correctAnswer.length > 0 ? "initial" : "none",
                }}
              >
                <span style={{ color: "#000" }}>{item.correctAnswer}</span>
              </h4>
              <Divider style={{ background: "#7459b3", height: 3 }} />
            </div>
          );
        })}
    </QuestionWrapper>
  );
};

export default TestQuestionWrapper;

const QuestionWrapper = styled.div`
  // width: 100%;
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
  // ::-webkit-scrollbar-thumb:horizontal{
  //     background: #000;
  //     border-radius: 10px;
  // }
`;
