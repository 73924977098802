import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Form, Button, Col, Row, Checkbox, Input } from "antd";
import { MailOutlined, UserOutlined, SettingOutlined } from "@ant-design/icons";
import QueueAnim from "rc-queue-anim";

const HearAbout = (props) => {
  const options = [
    { label: "School", value: "School" },
    { label: "Friends", value: "Freinds" },
    { label: "My Parents", value: "My Parents" },
    { label: "CODE", value: "CODE" },
    { label: "Other", value: "Other" },
    { label: "Emerge Alumni", value: "Emerge Alumni" },
    { label: "Facebook", value: "Facebook" },
    { label: "Youtube", value: "Youtube" },
    { label: "Emerge Referral", value: "Emerge Referral" },
  ];

  const previous = (e) => {
    props.prevStep();
  };

  return (
    <div style={{ width: "80%", marginBottom: "auto" }}>
      <QueueAnim type="right" delay={300} className="queue-simple">
        <Form key="a">
          <h3 style={{ textAlign: "center", margin: "20px " }}>
            How did you hear about Emerge?
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <CheckboxGroupWrapper
              options={options}
              value={props.infoState.knowThrough}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={previous}
              style={{ backgroundColor: "lightgray" }}
            >
              {" "}
              Previous
            </Button>
            {/* <Button
              size="large"
              htmlType="submit"
              type="primary"
              loading={props.loading}
            >
              Finish{" "}
            </Button> */}
          </div>
        </Form>
      </QueueAnim>
    </div>
  );
};

export default HearAbout;

const CheckboxGroupWrapper = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: flex;
  }
`;
