export const fetchData = async (setFetchedData) => {
  const apiUrl = `${process.env.REACT_APP_API_GET_DATA_URL}/getData`;
  // const apiUrl = `https://jsonplaceholder.typicode.com/users`;
  await fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => setFetchedData(data))
    .catch((e) => {
      console.log("e3", e);
    });
};
