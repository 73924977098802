import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Menu, Row, Col } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  SoundOutlined,
} from "@ant-design/icons";

const InfoNavbar = (props) => {
  const navStyle = props.step
    ? {
        borderColor: "#464797",
        color: "#464797",
      }
    : "";

  return (
    <Row style={{ width: "100%" }} justify="center">
      <Col span={24} style={{ background: "#f2f2f2" }}>
        <MenuWrapper mode="horizontal" selectedKeys={`${props.step}`}>
          <Menu.Item
            key="1"
            style={props.step === 1 ? navStyle : ""}
            onClick={() => props.setStep(1)}
            icon={<MailOutlined />}
          >
            ABOUT
          </Menu.Item>
          <Menu.Item
            key="2"
            style={props.step === 2 ? navStyle : ""}
            onClick={() => props.setStep(2)}
            icon={<PhoneOutlined />}
          >
            CONTACT
          </Menu.Item>
          <Menu.Item
            key="3"
            style={props.step === 3 ? navStyle : ""}
            onClick={() => props.setStep(3)}
            icon={<UsergroupAddOutlined />}
          >
            PARENT/GUARDIAN
          </Menu.Item>
          <Menu.Item
            key="4"
            style={props.step === 4 ? navStyle : ""}
            onClick={() => props.setStep(4)}
            icon={<BankOutlined />}
          >
            SCHOOL
          </Menu.Item>
          <Menu.Item
            key="5"
            style={props.step === 5 ? navStyle : ""}
            onClick={() => props.setStep(5)}
            icon={<SoundOutlined />}
          >
            HEAR ABOUT
          </Menu.Item>
        </MenuWrapper>
      </Col>
    </Row>
  );
};

export default InfoNavbar;

const MenuWrapper = styled(Menu)`
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  .ant-menu-item:hover {
    background-color: #464797;
    color: white !important;
    box-shadow: 0 0 4px 1px #464797 !important;
    transition: all 0.5s ease;
  }

  .ant-menu-item {
    padding: 0 10px !important;
    margin: -1px 20px 0 20px;
    border-radius: 3px;
    transition: all 0.5s ease;
    // font-size: 20px;
    // @media (max-width: 785px) {
    //   padding: 0 5px !important;
    // }
    // @media (max-width: 850px) {
    //   font-size: 12px;
    //   padding: 0 0 !important;
    // }
    // @media (max-width: 550px) {
    //   padding: 0 5px !important;
    // }
    // @media (max-width: 360px) {
    //   padding: 0 10px !important;
    // }
  }
`;
