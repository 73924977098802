import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import TableWrapper from "../../components/react-table";
import { connect, useDispatch } from "react-redux";
import { TableButton } from "../../components/styled-component/styledButton";
import {
  GET_ALL_COURSES,
  GET_REGISTERED_APPLICANTS,
} from "../../query/Queries";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Collapse, Select, Typography, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { message, Modal } from "antd";
import { setCookie } from "../../helper";
import RegisterModal from "../../components/modals/RegisterModal";
import UserDetailModal from "./UserDetailModal";
import { getUserStatus } from "../../actions/userStatus";

const { Panel } = Collapse;
const { Option } = Select;

const ManageCourse = (props) => {
  let dispatch = useDispatch();
  let history = useHistory();

  const handleAddNewCourse = () => {
    history.push("/add_new_course");
  };

  const handleCourseDetail = (data) => {
    history.push("/course_content_details", { data });
    // localStorage.setItem("courseId", data._id);
    // localStorage.setItem("courseName", data.courseName);
    setCookie("courseId", data._id);
    setCookie("courseName", data.courseName);
  };

  // // ==============Fetch all courses=============
  const { data: coursesData, loading: loadingCourses } = useQuery(
    GET_ALL_COURSES,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        props.dispatch({
          type: "GET_ALL_COURSES",
          payload: coursesData.getAllCourses,
        });
      },
      onError: (e) => message.error(e),
    }
  );

  // =============seccess added liberary message=============
  useEffect(() => {
    if (props.tableData.length < coursesData?.getAllCourses.length) {
      message.success("Topic successfully added in course section");
    }
  }, [props.tableData.length, coursesData]);

  // Table Filter
  const [data, setData] = useState([]);
  const [textbox, setTextbox] = useState("");
  // const [selectbox, setSelectbox] = useState("");

  const textboxSearch = (e) => {
    setTextbox(e.target.value);
  };

  // const selectboxSearch = (value) => {
  //   setSelectbox(value);
  // };

  const textSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      return value.courseName.toLowerCase().includes(textbox.toLowerCase());
    });
    setData(filteredData);
  };

  // const selectSearch = () => {
  //   let filteredData = props.tableData.filter((value) => {
  //     return value.status.toLowerCase().includes(selectbox.toLowerCase());
  //   });
  //   setData(filteredData);
  // };

  useEffect(() => textSearch(), [textbox]);
  // useEffect(() => selectSearch(), [selectbox]);
  useEffect(() => setData(props.tableData), [props.tableData]);

  const [modal, setModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const [dataModal, setDataModal] = useState("");

  const [courseId, setCourseId] = useState("");
  const [courseName, setCourseName] = useState("");

  // ======================Get Register courses===============
  const [
    getRegisteredApplicantFunc,
    { data: applicantsData, loading: getRegisterCourseLoading, error },
  ] = useLazyQuery(GET_REGISTERED_APPLICANTS, {
    variables: { courseId: courseId },
    onCompleted: () => {
      setDataModal(applicantsData);
      console.log(
        "getUserStatusApplicantsData",
        applicantsData && applicantsData?.getRegisteredApplicants[0]?.status
      );
      dispatch(
        getUserStatus(applicantsData && applicantsData?.getRegisteredApplicants)
      );
    },
    onError: () => {
      console.log(error, "error in app answer");
    },
  });

  const applicantsCourseIdFunc = (id) => setCourseId(id);

  const [courseNameDataState, setCourseNameDataState] = useState();

  const courseNameData = (data) => setCourseNameDataState(data?.courseName);
  console.log(data?.courseName, "courseNameData");

  useEffect(() => {
    getRegisteredApplicantFunc();
    applicantsCourseIdFunc();
    courseNameData();
  }, []);

  const CourseId = dataModal && dataModal.getRegisteredApplicants;

  console.log(CourseId, "CourseIdData");

  return (
    <div>
      <RegisterModal
        dataModal={dataModal}
        visible={visible}
        setVisible={setVisible}
        courseName={courseName}
        getRegisterCourseLoading={getRegisterCourseLoading}
        getRegisteredApplicantFunc={getRegisteredApplicantFunc}
        courseNameDataState={courseNameDataState}
      />

      <ContentHeader
        title={"Manage Course"}
        handleTextSearch={textboxSearch}
        // handleSelectSearch={selectboxSearch}
        handleAddNewCourse={handleAddNewCourse}
        count={props.tableData ? props.tableData.length : 0}
      />
      <TableWrapper
        tableData={data}
        loading={props.loading}
        pageSize={5}
        enableReInit={true}
        content={[
          {
            name: "Courses Name",
            id: "courseName",
            small: true,
            sortable: false,
            render: (r) => {
              setCourseName(r.original.courseName);
              return (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <Collapse
                      style={{ width: "100%" }}
                      expandIconPosition={"right"}
                    >
                      <Panel
                        header={r.original.courseName}
                        key={r.original._id}
                      >
                        <h2>Title:</h2>
                        <p>{r.original.courseName}</p>
                        <h2>Description:</h2>
                        <p>{r.original.description}</p>
                        <h2>Article or Video Link:</h2>
                        <Typography.Link
                          href={r.original.courseImageURL}
                          target="_blank"
                        >
                          {r.original.courseImageURL}
                        </Typography.Link>
                      </Panel>
                    </Collapse>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "1%",
                      }}
                    >
                      {console.log(
                        "getRegisteredApplicants?.length",
                        dataModal?.getRegisteredApplicants?.length
                      )}
                      <TableButton
                        // disabled={
                        //   dataModal?.getRegisteredApplicants?.length == 0
                        //     ? true
                        //     : false
                        // }
                        style={{ margin: 7 }}
                        onClick={() => {
                          showModal();
                          applicantsCourseIdFunc(r.original._id);
                          courseNameData(r.original);
                        }}
                      >
                        Applicants
                      </TableButton>
                      {/* 
                      {dataModal?.getRegisteredApplicants?.length == 0 ? (
                        <TableButton
                          disabled={true}
                          style={{ margin: 7 }}
                          onClick={() => {
                            showModal();
                            applicantsCourseIdFunc(r.original._id);
                          }}
                        >
                          Applicants
                        </TableButton>
                      ) : (
                        <TableButton
                          style={{ margin: 7 }}
                          onClick={() => {
                            showModal();
                            applicantsCourseIdFunc(r.original._id);
                          }}
                        >
                          Applicants
                        </TableButton>
                      )} */}
                      <TableButton
                        onClick={() => handleCourseDetail(r.original)}
                      >
                        Topics
                      </TableButton>
                    </div>
                  </div>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default connect((state) => {
  return {
    tableData: state.getAllCourses.AllCourses,
    garbage: state.getAllSalon.garbage,
  };
})(ManageCourse);
