import React from "react";
import styled from "styled-components";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Menu, Row, Col } from "antd";
import "./ApplicationNavbar.css";

const ApplicationNavbar = (props) => {
  console.log(props.step);

  const navStyle = props.step
    ? {
        borderColor: "#464797",
        color: "#464797",
      }
    : "";

  return (
    <Row style={{ width: "100%" }} justify="center">
      <Col span={24} style={{ background: "#f2f2f2" }}>
        <MenuWrapper mode="horizontal" selectedKeys={`${props.step}`}>
          <Menu.Item
            key="1"
            // style={props.step === 1 ? navStyle : ""}
            style={{
              marginLeft: "10px",
              borderColor: props.step === 1 ? "#464797" : "",
              color: props.step === 1 ? "#464797" : "",
            }}
            onClick={() => props.setStep(1)}
          >
            1
          </Menu.Item>
          <Menu.Item
            key="2"
            // style={props.step === 2 ? navStyle : ""}
            style={{
              marginLeft: "10px",
              borderColor: props.step === 2 ? "#464797" : "",
              color: props.step === 2 ? "#464797" : "",
            }}
            onClick={() => props.setStep(2)}
          >
            2
          </Menu.Item>
          <Menu.Item
            key="3"
            // style={props.step === 3 ? navStyle : ""}
            style={{
              marginLeft: "10px",
              borderColor: props.step === 3 ? "#464797" : "",
              color: props.step === 3 ? "#464797" : "",
            }}
            onClick={() => props.setStep(3)}
          >
            3
          </Menu.Item>
          <Menu.Item
            key="4"
            // style={props.step === 4 ? navStyle : ""}
            style={{
              marginLeft: "10px",
              borderColor: props.step === 4 ? "#464797" : "",
              color: props.step === 4 ? "#464797" : "",
            }}
            onClick={() => props.setStep(4)}
          >
            4
          </Menu.Item>
          <Menu.Item
            key="5"
            // style={props.step === 5 ? navStyle : ""}
            style={{
              marginLeft: "10px",
              borderColor: props.step === 5 ? "#464797" : "",
              color: props.step === 5 ? "#464797" : "",
            }}
            onClick={() => props.setStep(5)}
          >
            5
          </Menu.Item>
        </MenuWrapper>
      </Col>
    </Row>
  );
};

export default ApplicationNavbar;

const MenuWrapper = styled(Menu)`
  display: flex;
  justify-content: center;
  postition: absolute;
  top: 20;
  background: #f2f2f2;
  width: 90%;
  margin: 0 auto;
  .ant-menu-item:hover {
    background-color: #464797;
    color: white !important;
    box-shadow: 0 0 4px 1px #464797 !important;
    transition: all 0.5s ease;
  }
  .ant-menu-item {
    padding: 0 60px !important;
    border-radius: 3px;
    transition: all 0.5s ease;
    margin: -1px 20px 0 20px;
    @media (max-width: 850px) {
      padding: 0 50px !important;
    }
    @media (max-width: 750px) {
      padding: 0 40px !important;
    }
    @media (max-width: 650px) {
      padding: 0 30px !important;
    }
    @media (max-width: 550px) {
      padding: 0 15px !important;
    }
    @media (max-width: 460px) {
      padding: 0 10px !important;
    }
    @media (max-width: 360px) {
      padding: 0 5px !important;
    }
  }
`;
