export const TOGGLE_MODE = "TOGGLE_MODE"

export const OPEN_MODAL = "OPEN_MODAL"
export const CLOSE_MODAL = "CLOSE_MODAL"

//Dashboard screen
export const GET_USER_STATS = "GET_USER_STATS"
export const RECEIVED_USER_STATS = "RECEIVED_USERS_STATS"
export const GET_MONTHLY_USER_STATS = "GET_MONTHLY_USER_STATS"
export const RECEIVED_MONTHLY_USER_STATS = "RECEIVED_MONTHLY_USER_STATS"
export const GET_APP_DOWNLOADS = "GET_APP_DOWNLOADS"
export const RECEIVED_APP_DOWNLOADS = "RECEIVED_APP_DOWNLOADS"

//Users screen
export const GET_USERS = "GET_USERS"
export const RECEIVED_USERS = "RECEIVED_USERS"