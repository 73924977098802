import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import HeaderWrapper from "../../components/header/index";
import { LeftSideMenuWrapper } from "../../components/sidebars/index";
import ContentWrapper from "../../components/maincontent/index";
import FooterWrapper from "../../components/footer/index";

//------------------//
//     All Views    //
//------------------//

import Dashboard from "./Dashboard";
import Customers from "./Customers";
import AddNewCourse from "./AddNewCourse";
import CourseContentDetails from "./CourseContentDetails";
import AddNewDurationContent from "./AddNewDurationContent";
import ManageCourse from "./ManageCourse";
import ManageLibrary from "./ManageLibrary";
import CreateQuiz from "./CreateQuiz";
import AddNewLibraryContent from "./AddNewLibraryContent";
import StudentCourseDetails from "../shell/studentInformation/StudentCourseDetails";

import { useQuery } from "@apollo/client";
import {
  GET_ALL_USERS,
  GET_ALL_LIBRARIES,
  GET_ALL_COURSES,
} from "../../query/Queries";

import ErrorModal from "../../components/modals/errorModal";

const Shell = (props) => {
  const [state, setState] = useState({ collapsed: false });
  const [queryError, setQueryError] = useState({
    occur: false,
    error: "",
  });

  //----------------------------------------------------//
  //   All Query Run here. All table data load at once  //
  //----------------------------------------------------//
  const { data: librariesData, loading: loadingLibraries } = useQuery(
    GET_ALL_LIBRARIES,
    {
      onCompleted: () =>
        props.dispatch({
          type: "GET_ALL_LIBRARIES",
          payload: librariesData.getAllLibraries,
        }),
      onError: (e) => setQueryError({ occur: true, error: e }),
    }
  );

  const { data: userData, loading: userLoading } = useQuery(GET_ALL_USERS, {
    onCompleted: () => {
      // console.log("User Query >>> ", userData.users);
      props.dispatch({
        type: "GET_ALL_USERS",
        payload: userData.users,
      });
    },
    onError: (e) => setQueryError({ occur: true, error: e }),
  });

  const { data: coursesData, loading: loadingCourses } = useQuery(
    GET_ALL_COURSES,
    {
      onCompleted: () =>
        props.dispatch({
          type: "GET_ALL_COURSES",
          payload: coursesData.getAllCourses,
        }),
      onError: (e) => setQueryError({ occur: true, error: e }),
    }
  );

  //-----------------------------//
  //   Toggle for Sidebar ||   //
  //------------------------\/---//

  const toggleCollapsed = () => {
    setState({
      collapsed: !state.collapsed,
    });
  };

  return (
    <div className="App">
      <ErrorModal
        title="Error"
        visible={queryError.occur}
        onCancel={setQueryError}
        errorMessage={queryError.error}
      />
      <Layout>
        <LeftSideMenuWrapper state={state} />

        <Layout>
          <HeaderWrapper
            state={state}
            toggleCollapsed={toggleCollapsed}
            dispatch={props.dispatch}
          />

          <ContentWrapper>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/manage_user">
                <Customers loading={userLoading} />
              </Route>
              <Route exact path="/add_new_course" component={AddNewCourse} />
              <Route
                exact
                path="/course_content_details"
                component={CourseContentDetails}
              />
              <Route
                exact
                path="/add_course_content"
                component={AddNewDurationContent}
              />
              <Route exact path="/create_quiz" component={CreateQuiz} />
              <Route
                exact
                path="/student_course_details"
                component={StudentCourseDetails}
              />
              <Route
                exact
                path="/add_library_content"
                component={AddNewLibraryContent}
              />
              <Route exact path="/manage_course">
                <ManageCourse loading={loadingCourses} />
              </Route>
              <Route exact path="/manage_library">
                <ManageLibrary loading={loadingLibraries} />
              </Route>

              {/* <Route exact path="/terms" component={PrivacyPolicy} /> */}
            </Switch>
          </ContentWrapper>

          <FooterWrapper />
        </Layout>
      </Layout>
    </div>
  );
};

export default Shell;
