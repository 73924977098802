import React, { useState, useEffect } from "react";
import { Table, Space, Switch, Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { getProducts } from "../../redux/actions/products";
import { EditOutlined, SwapOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

const { Column } = Table;

const ModalTable = () => {
  //   const [search, setSearch] = useState("");
  //   const dispatch = useDispatch();
  //   const products = useSelector((state) => state.products.products.product);
  //   console.log(products);

  //   useEffect(() => {
  //     dispatch(getProducts([]));
  //   }, []);

  return (
    <StyledModal>
      <Row>
        <Col span={24}>
          {/* <Table dataSource={products}> */}
          <Table>
            <Column title="Product Title" dataIndex="title" key="title" />
            <Column title="Product Type" dataIndex="type" key="type" />
            <Column title="Product ID at GRS" dataIndex="grs" key="grs" />
            <Column title="Status" dataIndex="soldDate" key="soldDate" />
            <Column
              title="Action"
              key="action"
              //   render={(text, record) => (
              //     <Space size="middle">
              //       <Link>
              //         <EditOutlined />
              //       </Link>
              //       {record.actions.activate === false ? (
              //         <>
              //           <Switch checked={false} />
              //           <p>Deactivate</p>
              //         </>
              //       ) : (
              //         <>
              //           <Switch checked={true} />
              //           <p>Activate</p>
              //         </>
              //       )}
              //     </Space>
              //   )}
            />
          </Table>
        </Col>
      </Row>
    </StyledModal>
  );
};

export default ModalTable;

const StyledModal = styled.div`
  width: 100%;
  transform: translate(0, 0);
`;
