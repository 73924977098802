import React from "react";
import { Skeleton, Row, Col } from "antd";
import CardWrapper from "../../components/card";
import {
    StyledButton,
    TableButton,
  } from "../../components/styled-component/styledButton";

const UserLibraryCard = (props) => (
  <CardWrapper
    className={props.styledClass}
    style={{ borderRadius: 3, padding: "0" }}
    cover={<img src={props.img} style={{height: 120, borderRadius: 5}}/>}
  >
    <Skeleton loading={props.loading} avatar active>
      <div style={{padding: 15}}>
        <div style={{width: '100%'}}>
        </div>
          <h2>Library</h2>
        <Row type="flex" justify="space-between">
        <Col>
          <p style={{ fontWeight: 450 }}>No of Articles</p>
          <p style={{ fontWeight: "bolder", fontSize: 16 }}>{props.libraryAllArticle.length}</p>
        </Col>
        <Col>
        <p style={{ fontWeight: 450 }}>No of Videos</p>
        <p style={{ fontWeight: "bolder", fontSize: 16 }}>{props.libraryAllVideo.length}</p>
        </Col>
        </Row>
        <Col span={6} offset={6}>
        {/* <StyledButton style={{width: 'auto'}}>
            View Profile
        </StyledButton> */}
        </Col>
      </div>
    </Skeleton>
  </CardWrapper>
);

export default UserLibraryCard;
