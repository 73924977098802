// const loginStatus = {
//   isLoggedin: true,
//   token: "",
// };
const isLoggedin = (
  state = {
    isLoggedin: false,
    token: "",
    permissions: [],
  },
  action
) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isLoggedin: !state.isLoggedin,
        token: action.payload.token,
        permissions: action.payload.permissions,
      };
    case "LOGOUT":
      return { ...state, isLoggedin: null, token: null };
    default:
      return state;
  }
};
export default isLoggedin;
