import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Switch,
  Space,
  Tooltip,
  Row,
  Col,
  Button,
  Input,
} from "antd";
import ModalTable from "../table/ModalTable";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../api/index";
import {
  ColumnWidthOutlined,
  EditOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import InputWrapper from "../../components/input";
import { FiSearch } from "react-icons/fi";
import { useMutation, useQuery } from "react-apollo";
import { CHANGE_REGISTER_COURSE_STATUS } from "../../query/Mutations";
import {
  GET_REGISTERED_APPLICANTS,
  GET_COURSE_DETAIL,
} from "../../query/Queries";
import {
  userAcceptedStatus,
  getUserStatus,
  userDeclineStatus,
} from "../../actions/userStatus";

const { Column } = Table;

const RegisterModal = ({
  setVisible,
  visible,
  dataModal,
  courseName,
  getRegisteredApplicantFunc,
  CourseId,
  getRegisterCourseLoading,
  courseNameDataState,
}) => {
  const [fetchedData, setFetchedData] = useState([]);

  const dispatch = useDispatch();

  const course = fetchedData?.course;

  // const status = dataModal && dataModal?.getRegisteredApplicants[0]?.status;
  // useEffect(() => {
  //   dispatch(getUserStatus(status));
  // }, []);

  // console.log("fetchedData", status);

  useEffect(() => {
    fetchData(setFetchedData);
  }, []);

  const handleOk = (e) => {
    return setVisible(false);
  };

  const handleCancel = (e) => {
    return setVisible(false);
  };

  //step 1
  const [search, setSearch] = useState("");

  //step 3
  function handleSearchChange(e) {
    e.preventDefault();
    setSearch(e.target.value.toLowerCase());
  }

  //step 4
  const filtered = (search) => (f) =>
    f?.userId?.firstName?.toLowerCase().includes(search);

  const CourseName =
    dataModal && dataModal.getRegisteredApplicants[0]?.courseId.courseName;
  console.log(CourseName, "CourseName");

  const status = dataModal && dataModal.getRegisteredApplicants;
  console.log(status, "dataModal786");

  // ======change status =============
  const [changeRegisterCourseStatus, { loading: loadingApplied }] = useMutation(
    CHANGE_REGISTER_COURSE_STATUS
  );

  const [changeStatus, setChangeStatus] = useState();
  const [courseId, setCourseId] = useState("");

  const applicantsCourseIdFunc = (id) => setCourseId(id);

  const handleAccept = async (record) => {
    let userId = record?.userId._id;
    let courseIds = record?.courseId._id;

    try {
      if (userId && courseId) {
        console.log("typeof > ", typeof userId);
        console.log("typeof courseId> ", typeof courseId);

        const res = await changeRegisterCourseStatus({
          variables: {
            userId: userId,
            courseId: courseIds,
            status: "approved",
          },
        });
        console.log("handleAcceptData", res.data);
        setChangeStatus(res.data.changeRegisterCourseStatus.status);
        dispatch(userAcceptedStatus(res.data.changeRegisterCourseStatus));
      }
    } catch (e) {
      console.log(e, "error in status");
    }
  };

  const handleDecline = async (userId, courseId) => {
    console.log(userId, courseId, status, "userId, courseId, status");
    try {
      if (userId && courseId) {
        console.log("typeof > ", typeof userId);
        console.log("typeof courseId> ", typeof courseId);

        const res = await changeRegisterCourseStatus({
          variables: {
            userId: userId,
            courseId: courseId,
            status: "rejected",
          },
        });
        console.log("handleAcceptData", res.data);
        setChangeStatus(res.data.changeRegisterCourseStatus.status);
        dispatch(userDeclineStatus(res.data.changeRegisterCourseStatus));
      }
    } catch (e) {
      console.log(e, "error in status");
    }
  };

  const userStatusReducer = useSelector((state) => state.userStatus.status);

  const [getRecord, setGetRecord] = useState();

  const gettingData = async () => {
    try {
      await dispatch(getUserStatus(getRecord));
    } catch (e) {
      console.log(e, "error in status");
    }
  };

  useEffect(() => {
    gettingData();
  }, []);

  return (
    <>
      <StyledModal
        title={"Course name: " + courseNameDataState}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col span={24}>
            <Col span={12} style={{ float: "left" }}></Col>
            <Col span={12} style={{ float: "right", display: "flex" }}>
              {/* step 2 */}
              <InputWrapper
                style={{ marginBottom: 20 }}
                value={search}
                onChange={handleSearchChange}
                name="textbox"
                prefix={<FiSearch />}
                allowClear={true}
                placeholder="Search"
              />
            </Col>
          </Col>
        </Row>

        {/* step 5 */}
        <Table
          loading={getRegisterCourseLoading || loadingApplied}
          // dataSource={dataModal.getRegisteredApplicants?.filter(
          //   filtered(search)
          // )}
          dataSource={userStatusReducer?.filter(filtered(search))}
        >
          <Column
            title="Username"
            key="firstName"
            render={(record) => record.userId.firstName}
            width={200}
          />
          <Column
            title="Email"
            key="email"
            render={(record) => record.userId.email}
            width={300}
          />
          <Column
            title="Action"
            key="status"
            render={(record) => (
              <>
                {setGetRecord(record)}

                {applicantsCourseIdFunc(record.userId._id)}
                {record.status === "applied" && (
                  <>
                    <Tooltip title="Accept">
                      <AiOutlineCheckCircle
                        // onClick={() => console.log("Accept")}
                        onClick={() => handleAccept(record)}
                        style={{
                          color: "green",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Decline">
                      <AiOutlineCloseCircle
                        // onClick={() => console.log("Decline")}
                        onClick={() =>
                          handleDecline(
                            record.userId._id,
                            record.courseId._id,
                            record.status
                          )
                        }
                        style={{
                          color: "red",
                          fontSize: "25px",
                          cursor: "pointer",
                          marginLeft: 15,
                        }}
                      />
                    </Tooltip>
                  </>
                )}
                {record.status === "approved" && (
                  <p
                    style={{
                      color: "green",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    Approved
                  </p>
                )}
                {record.status === "rejected" && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: 0,
                    }}
                  >
                    Rejected
                  </p>
                )}
              </>
            )}
          />
        </Table>
      </StyledModal>
    </>
  );
};

export default RegisterModal;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 140%;
    transform: translate(-100px, 10px);
  }
`;
