import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  color: #1d194d;
  cursor: pointer;
  font-weight: 900;

  &:hover {
    color: #49d3ce;
  }
`;
