import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import TableWrapper from "../../components/react-table";
import RadioWrapper from "../../components/radioButton/Index";
import userAvatar from "../../images/Tim-01.png";
import AvatarWrapper from "../../components/avatar/index";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  CHANGE_PAID_STATUS,
  APPROVED_USER,
  REJECT_USER,
} from "../../query/Mutations";
import { Select, Tooltip, Radio, Modal } from "antd";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import UserDetailModal from "./UserDetailModal";
import { setCookie } from "../../helper";

const { Option } = Select;

const Customers = (props) => {
  const history = useHistory();
  console.log("Props >>>> ", props);
  const [customerId, setCustomerId] = useState("");
  const [changePaidStatus, { loading }] = useMutation(CHANGE_PAID_STATUS);
  const handleChange = async (e, id) => {
    try {
      const res = await changePaidStatus({
        variables: {
          userId: id,
        },
      });
      props.dispatch({
        type: "CHANGE_USER_STATUS",
        payload: { isPaid: res.data.changePaidStatus.isPaid, id: id },
      });
    } catch (error) {
      console.log(error, "error in handle change");
    }
  };

  // Approved user handling
  const [approveUser, { loading: approvedLoading }] = useMutation(
    APPROVED_USER
  );
  const handleApproveUser = async (r, id) => {
    try {
      const res = await approveUser({
        variables: { userId: r.original._id },
      });
      props.dispatch({
        type: "CHANGE_APPLICATION_STATUS",
        payload: {
          applicationStatus: res.data.approveUser.applicationStatus,
          id: id,
        },
      });
      console.log(res, "res in handle approve");
    } catch (error) {
      console.log(error, "error in handle approve");
    }
  };

  // Rejected user handling
  const [rejectUser, { loading: rejectLoading }] = useMutation(REJECT_USER);
  const handleRejectUser = async (r, id) => {
    try {
      const res = await rejectUser({
        variables: { userId: r.original._id },
      });
      props.dispatch({
        type: "CHANGE_APPLICATION_STATUS",
        payload: {
          applicationStatus: res.data.rejectUser.applicationStatus,
          id: id,
        },
      });
      console.log(res, "res in handle reject");
    } catch (error) {
      console.log(error, "error in handle reject");
    }
  };

  // Table Filter
  const [data, setData] = useState([]);
  const [textbox, setTextbox] = useState("");
  const [selectbox, setSelectbox] = useState("");
  const [modal, setModal] = useState(false);
  const [UserData, setUserData] = useState("");

  const textboxSearch = (e) => {
    setTextbox(e.target.value);
  };

  const selectboxSearch = (value) => {
    setSelectbox(value);
  };

  const textSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      return (
        value.firstName.toLowerCase().includes(textbox.toLowerCase()) ||
        value.lastName.toLowerCase().includes(textbox.toLowerCase()) ||
        value.email.toLowerCase().includes(textbox.toLowerCase())
      );
    });
    setData(filteredData);
  };

  const selectSearch = () => {
    let filteredData = props.tableData.filter((value) => {
      console.log(value, "value >>>");
      return value.applicationStatus
        .toLowerCase()
        .includes(selectbox.toLowerCase());
    });
    setData(filteredData);
  };

  // Actions
  const actionHandle = (r) => {
    console.log("Response >>>> ", r);
    if (r.original.applicationStatus === "submitted") {
      return (
        <>
          <Tooltip title="Accept">
            <AiOutlineCheckCircle
              onClick={() => handleApproveUser(r, r.original._id)}
              style={{ color: "green", fontSize: "25px", cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Decline">
            <AiOutlineCloseCircle
              onClick={() => handleRejectUser(r, r.original._id)}
              style={{
                color: "red",
                fontSize: "25px",
                cursor: "pointer",
                marginLeft: 15,
              }}
            />
          </Tooltip>
        </>
      );
    } else if (r.original.applicationStatus === "approved") {
      return (
        <p
          style={{
            color: "green",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Accepted
        </p>
      );
    } else if (r.original.applicationStatus === "rejected") {
      return (
        <p
          style={{
            color: "red",
            fontSize: "12px",
            marginBottom: 0,
          }}
        >
          Rejected
        </p>
      );
    } else if (r.original.applicationStatus === "pending") {
      return (
        <p
          style={{
            display: "inline-block",
            color: "black",
            fontSize: "12px",
            marginBottom: 0,
            width: "intrinsic" /* Safari/WebKit uses a non-standard name */,
            width: "-moz-max-content" /* Firefox/Gecko */,
            width: "-webkit-max-content" /* Chrome */,
            width: "max-content",
          }}
        >
          Application not submitted
        </p>
      );
    }
  };

  useEffect(() => textSearch(), [textbox]);
  useEffect(() => selectSearch(), [selectbox]);
  useEffect(() => setData(props.tableData), [props.tableData]);

  const handleStudentCourseDetail = (data) => {
    history.push("/student_course_details", { data });
    console.log("cbhs", data);
    // localStorage.setItem("courseId", data._id);
    // localStorage.setItem("courseName", data.courseName);
    setCookie("userId", data._id);
    setCookie("courseName", data.courseName);
  };

  console.log("UserData", UserData);
  return (
    <div style={{ width: "100%" }}>
      <UserDetailModal
        modal={modal}
        setModal={setModal}
        data={UserData}
        title="User Detail"
      />
      <ContentHeader
        title="Manage User"
        handleTextSearch={textboxSearch}
        handleSelectSearch={selectboxSearch}
        // count={props.tableData ? props.tableData.length : 0}
        count={data.length}
        options={[
          { label: "View All", value: "" },
          { label: "Accepted", value: "approved" },
          { label: "Rejected", value: "rejected" },
          { label: "Others", value: "pending" },
          { label: "Pending", value: "submitted" },
        ]}
      />
      <div style={{ minWidth: 1280, overflow: "auto", padding: 5 }}>
        <TableWrapper
          tableData={data}
          loading={props.loading}
          pageSize={6}
          enableReInit={true}
          content={[
            {
              name: "Student Name",
              id: "firstName ",
              small: true,
              sortable: false,
              render: (r) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AvatarWrapper
                      size={"small"}
                      src={userAvatar}
                    ></AvatarWrapper>
                    <span>
                      {r.original.firstName} {r.original.lastName}
                    </span>
                  </div>
                );
              },
            },
            { name: "Email", id: "email", sortable: false },
            {
              name: "Phone Number",
              id: "phone",
              sortable: false,
            },
            {
              name: "Paid",
              id: "actions",
              sortable: false,
              render: (r) => {
                return (
                  <div>
                    <RadioWrapper
                      key={r.original._id}
                      value={r.original.isPaid}
                      onChange={(e) => {
                        setCustomerId(r.original._id);
                        handleChange(e, r.original._id);
                      }}
                    >
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </RadioWrapper>
                  </div>
                );
              },
            },

            {
              name: "Details",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <TableButton
                    onClick={() => {
                      setModal(true);
                      setUserData(r.original);
                    }}
                    disabled={
                      r.original.applicationStatus === "pending"
                        ? "disabled"
                        : ""
                    }
                  >
                    <b>View</b>
                  </TableButton>
                );
              },
            },
            {
              name: "Course",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <TableButton
                    onClick={() => handleStudentCourseDetail(r.original)}
                    disabled={
                      r.original.applicationStatus === "pending"
                        ? "disabled"
                        : ""
                    }
                  >
                    {console.log("r_id", r.original._id)}
                    {/* <Link to=`{r.original._id}/student_course_details`> */}
                    <b>View</b>
                    {/* </Link> */}
                  </TableButton>
                );
              },
            },
            {
              name: "Actions",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    {actionHandle(r)}
                  </div>
                );
              },
            },
            {
              name: "Public Profile",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <StyledButton
                    // onClick={() =>
                    //   window.open(`${r.original.profileLink}`, "_blank")
                    // }
                    disabled={
                      r.original.applicationStatus === "approved"
                        ? ""
                        : "disabled"
                    }
                  >
                    <a href={r.original.profileLink} target="_blank">
                      <b>Public Profile</b>
                    </a>
                  </StyledButton>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state");
  return {
    tableData: state.getAllUsers.AllUsers,
    garbage: state.getAllUsers.garbage,
  };
};

export default connect(mapStateToProps)(Customers);
