//-------------------------------------------------------------------------------------------//
// Encrypting role and permissions key. Only decrypting in Shell and HeaderContent component //
//-------------------------------------------------------------------------------------------//

import { setCookie } from "../helper";

export const loginAction = (token) => {
  // localStorage.setItem("token", token.token);
  // localStorage.setItem("loggedIn", "true");
  // localStorage.setItem("DeviceId", token.deviceId);

  setCookie("token", token.token);
  setCookie("loggedIn", "true");
  setCookie("DeviceId", token.deviceId);

  return { type: "LOGIN", payload: token };
};
