// import Select from "../components/input/Select";
// import Option from "../components/input/SelectOptions";
// import Button from "../components/button/index";
// import RootForm from "../components/form/index";
// import { loginValidations } from "../configurations/Schemas";
// import { Layout, Row, Col, AutoComplete } from "antd";
// import Card from "../components/card/index";
// import Icon from "../components/Icon/index";
// import { UserAddOutlined, LockOutlined } from "@ant-design/icons";

import React, { useState } from "react";
import { loginAction } from "../actions/login";
import InputWrapper from "../components/input";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import LoginTopImage from "../images/loginTopImage.png";
import { Form } from "antd";
import ErrorModal from "../components/modals/errorModal";
import { USER_LOGIN } from "../query/Mutations";
import { StyledCheckbox } from "../components/input/CheckBox";
import { LockFilled } from "@ant-design/icons";
import { StyledLink } from "../components/styled-component/styledLink";
import { StyledButton } from "../components/styled-component/styledButton";
import InputFeild from "../components/input/InputField";

const LoginNew = (props) => {
  const [errorModal, setErrorModal] = useState({ vis: false, e: "" });

  const [loginInfo, setState] = useState({
    email: "",
    password: "",
  });

  const [loginMutation, { loading }] = useMutation(USER_LOGIN);
  const handleSubmit = async () => {
    let myTempDeviceId = Math.random().toString();
    try {
      const res = await loginMutation({
        variables: {
          email: loginInfo.email.toLocaleLowerCase(),
          password: loginInfo.password,
          deviceId: myTempDeviceId,
        },
      });
      console.log("Data after Login==========>", res);
      props.login({
        token: res.data.loginUser.jwtToken.token,
        deviceId: myTempDeviceId,
      });
    } catch (err) {
      setErrorModal({ vis: true, e: err });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...loginInfo, [name]: value });
  };

  return (
    <div>
      <ErrorModal
        title="Error"
        visible={errorModal.vis}
        onCancel={setErrorModal}
        // onOk={setErrorModal}
        errorMessage={errorModal.e}
      />

      {/* Main Column */}
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Login Card */}
        <div
          style={{
            width: 448,
            height: 512,
            padding: "0 32px",
            backgroundColor: "#fff",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)",
          }}
        >
          <img
            src={LoginTopImage}
            alt="Logo"
            width={448}
            style={{ position: "relative", right: 32 }}
          />
          <div
            style={{
              textAlign: "left",
              position: "relative",
              top: "-80px",
              left: "0px",
            }}
          >
            <span style={{ fontSize: 16, fontWeight: 600, color: "#464797" }}>
              Welcome Back
            </span>
            <br />
            <span style={{ fontSize: 14, fontWeight: 400, color: "#464797" }}>
              Sign in to continue to Emerge Forward
            </span>
          </div>

          <Form onFinish={handleSubmit}>
            <Form.Item
              name="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter valid email",
                },
              ]}
              validateFirst={false}
              initialValue={loginInfo.email}
            >
              <InputFeild
                label="Email"
                name="email"
                value={loginInfo.email}
                placeholder="abc@xyz.com"
                onChange={handleChange}
                onKeyPress={(e) => e.which === 13 && handleSubmit()}
                style={{ margin: 0 }}
              />
            </Form.Item>

            <Form.Item
              name="Password"
              rules={[
                {
                  type: "string",
                  required: true,
                  min: 8,
                },
              ]}
              validateFirst={false}
            >
              <InputFeild
                label="Password"
                name="password"
                value={loginInfo.password}
                placeholder="Password"
                type="password"
                onChange={handleChange}
                onKeyPress={(e) => e.which === 13 && handleSubmit()}
                style={{ margin: 0 }}
              />
            </Form.Item>

            <div style={{ margin: "24px 0", textAlign: "left" }}>
              <StyledCheckbox>Remember me</StyledCheckbox>
            </div>
            <br />
            <StyledButton
              style={{ height: 40 }}
              loading={loading}
              htmlType="submit"
            >
              Login
            </StyledButton>
          </Form>
          {/* <div style={{ marginTop: 16 }}>
            <StyledLink to="/forgotpassword">
              <LockFilled /> Forgot Password
            </StyledLink>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  login: (token) => dispatch(loginAction(token)),
});

export default connect(null, mapDispatchToProps)(LoginNew);
