import React, { useState, useEffect } from "react";
import ContentHeader from "./ContentHeader";
import TableWrapper from "../../components/react-table";
import SwitchWrapper from "../../components/switch/index";
import {
  TableButton,
  StyledButton,
} from "../../components/styled-component/styledButton";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { GET_COURSE_DETAIL } from "../../query/Queries";
import { CHANGE_COURSE_DETAIL_STATUS } from "../../query/Mutations";
import CourseDetailModal from "./CourseDetailModal";
import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../helper";

const CourseContentDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  console.log(
    location.state,
    "My location state",
    // localStorage.getItem("courseId")
    getCookie("courseId")
  );
  // let courseId = localStorage.getItem("courseId");
  // let courseName = localStorage.getItem("courseName");
  let courseId = getCookie("courseId");
  let courseName = getCookie("courseName");

  const [itemId, setItemId] = useState(null);
  const [modal, setModal] = useState(false);
  const [courseTopicData, setCourseTopicData] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [textbox, setTextbox] = useState("");
  console.log("Course Data >>> ", courseData);
  // ==========get course details==============
  const [loadCourseDetail, { data, loading, error }] = useLazyQuery(
    GET_COURSE_DETAIL,
    {
      onCompleted: () => {
        // setCourseData(data.getCourseDetailById);
        props.dispatch({
          type: "GET_ALL_CONTENT_DETAILS",
          payload: data.getCourseDetailById,
        });
      },
      onError: () => {
        console.log(error, "error in course content detail");
      },
      fetchPolicy: "network-only",
    }
  );

  // ======change status =============
  const [changeCourseDetailStatus, { loading: itemLoading }] = useMutation(
    CHANGE_COURSE_DETAIL_STATUS
  );
  const handleChange = async (e, id) => {
    try {
      const res = await changeCourseDetailStatus({
        variables: {
          _id: id,
        },
      });
      props.dispatch({
        type: "CHANGE_CONTENT_DETAILS_STATUS",
        payload: {
          topicStatus: res.data.changeCourseDetailStatus.status,
          id: id,
        },
      });
      console.log(res, "res in course content details");
      loadCourseDetail({ variables: { courseId: courseId } });
    } catch (error) {
      console.log(error, "error in handle change");
    }
  };

  // =======text search =========
  const textboxSearch = (e) => {
    setTextbox(e.target.value);
  };

  const textSearch = () => {
    console.log("Textbox >>>", textbox);
    let filteredData = props.getAllContentDetails?.filter((value) => {
      return value.topicName.toLowerCase().includes(textbox.toLowerCase());
      // value.week.includes(textbox)
    });
    setCourseData(filteredData);
    console.log("Filtered Data >>>> ", filteredData);
  };

  const handleAddNewCourseContent = () => {
    history.push("/add_course_content");
  };

  useEffect(() => loadCourseDetail({ variables: { courseId: courseId } }), []);
  useEffect(() => textSearch(), [textbox]);
  useEffect(
    () => setCourseData(props.getAllContentDetails),
    [props.getAllContentDetails]
  );

  console.log("Course Data >>>", courseData);

  courseData &&
    courseData.sort((data1, data2) => {
      return data1.week - data2.week;
    });
  return (
    <div style={{ width: "100%" }}>
      <CourseDetailModal
        modal={modal}
        setModal={setModal}
        data={courseTopicData}
        title="Course Topic Detail"
      />
      <ContentHeader
        title="Course Content Detail"
        handleAddNewCourseContent={handleAddNewCourseContent}
        handleTextSearch={textboxSearch}
        // handleSelectSearch={selectboxSearch}
        // count={props.tableData ? props.tableData.length : 0}
        count={courseData.length}
      />
      <div>
        <h2>
          <span style={{ color: "gray" }}>Course Name: </span>
          {courseName}
        </h2>
      </div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/manage_course">Manage Course</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Course Content Detail</Breadcrumb.Item>
      </Breadcrumb>

      {!courseData ? (
        "loading..."
      ) : (
        <TableWrapper
          tableData={courseData}
          loading={loading}
          pageSize={5}
          enableReInit={true}
          content={[
            {
              name: "No of Weeks",
              id: "week",
              small: true,
              sortable: false,
            },
            { name: "Topics Name", id: "topicName", sortable: false },
            {
              name: "Details",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <TableButton
                    onClick={() => {
                      setModal(true);
                      setCourseTopicData(r.original);
                    }}
                  >
                    <b>View</b>
                  </TableButton>
                );
              },
            },
            {
              name: "Status",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <div>
                    <SwitchWrapper
                      key={r.original._id}
                      loading={itemId === r.original._id && itemLoading}
                      defaultChecked={r.original.status === true ? true : false}
                      style={{ marginRight: 10 }}
                      onChange={(e) => {
                        setItemId(r.original._id);
                        handleChange(e, r.original._id);
                      }}
                    ></SwitchWrapper>
                    {r.original.status === true ? "Active" : "De-Active"}
                  </div>
                );
              },
            },
            {
              name: "Actions",
              id: "status",
              sortable: false,
              render: (r) => {
                return (
                  <div>
                    {r.original.status === true ? (
                      <StyledButton
                        onClick={() => history.push("/create_quiz", r.original)}
                      >
                        Create Quiz
                      </StyledButton>
                    ) : (
                      ""
                    )}
                    {/* {r.original.status === true ? "Active" : "In-Active"} */}
                  </div>
                );
              },
            },
          ]}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "state >>>");
  return {
    tableData: state.getAllUsers.AllUsers,
    garbage: state.getAllUsers.garbage,
    getAllContentDetails: state.getAllContentDetails.getAllContentData,
  };
};

export default connect(mapStateToProps, null)(CourseContentDetails);
